import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';
import { LoginService } from '../../Services/login.service';
import { Router } from '@angular/router';
import { UserModel } from '../../Models/user-model';
import { Observable } from 'rxjs';
import { LoginComponent } from '../../pages/login/login.component';
 
@Component({
  selector: 'cdk-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
	isOpen: boolean = false;

  	@Input() currentUser = null;
 
  myUser: Observable<string>;

   loggedUser: string;
  	@HostListener('document:click', ['$event', '$event.target'])
  	onClick(event: MouseEvent, targetElement: HTMLElement) {
    	if (!targetElement) {
     		return;
    	}

    	const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    	if (!clickedInside) {
      		this.isOpen = false;
    	}
  	}
  	

  constructor(private router: Router, private elementRef: ElementRef, private login: LoginService) {
    this.myUser = this.login.getLogedUserName();
    this.login.getLogedUserName().subscribe((data) => {

      this.loggedUser = data;

    });

   
    
  }

  logout() {

    this.login.logout();
   
    this.router.navigate(['/login']);
  }

  changePassword() {

    //this.login.logout();
    //debugger;
    this.router.navigate(['/auth/pages/changepassword']);
  }

  ngOnInit() {
    this.myUser = this.login.getLogedUserName();
    this.login.getLogedUserName().subscribe((data) => {

      this.loggedUser = data;

    });
  



    


  	}

}
