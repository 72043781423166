import { Component, OnInit, Inject } from '@angular/core';
import { UserModel } from 'src/app/Models/user-model';
import { DepartmentModel } from 'src/app/Models/department-model';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RepositoryService } from 'src/app/Services/repository.service';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-department-edit-dialog',
  templateUrl: './department-edit-dialog.component.html',
  styleUrls: ['./department-edit-dialog.component.css']
})
export class DepartmentEditDialogComponent implements OnInit {


  // #region Variables Declarations
  public loggedUserID: string;

  users: UserModel[];
  selectedHods: UserModel[];
  department: DepartmentModel;
  departmentForm: FormGroup;


  // #endregion Variables Declarations



  // #region Toaster Service registration and defualt position
  private toasterService: ToasterService;
  public config: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: false,
    positionClass: 'toast-top-center'
  });
  // #endregion

  // #region Hod search

  bmSearch(query: string) {
    //debugger;
    const result = this.selectHods(query);
    this.selectedHods = result;
  }

  selectHods(query: string): UserModel[] {


    const result: UserModel[] = [];
    for (const roleObj of this.users) {
      if ((roleObj.firstName + ' ' + roleObj.lastName).toLowerCase().indexOf(query) > -1) {
        result.push(roleObj);
      }
    }
    return result;
  }
  // #endregion

  ngOnInit() {
    this.loggedUserID = localStorage.getItem('userId');
    this.buildForm();
  }

  // #region Toaster  Pop ups for actions
  showUpdateSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Updated!');
  }
  showDuplicate() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'Duplicate', 'Duplicate Record!');
  }
  showSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Created!');
  }
  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }
  // #endregion Toaster  Pop ups for actions

  // #region Constructors
  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<DepartmentEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DepartmentModel,
    private repository: RepositoryService,
    toasterService: ToasterService) {

    this.toasterService = toasterService;
    this.LoadDDLData();

  }
  // #endregion Constructors 


  // #region  formControl Validators and Validators Message
  //formControl = new FormControl('', [
  //  Validators.required
  //  // Validators.email,
  //]);

  //getErrorMessage() {
  //  return this.formControl.hasError('required') ? 'Required field' :
  //    this.formControl.hasError('email') ? 'Not a valid email' :
  //      '';
  //}

  buildForm() {
    this.departmentForm = this.fb.group({
      'departmentName': ['', [
        Validators.required
      ]
      ]
      ,
      'hod': ['', [
        Validators.required,
        //Validators.pattern('^[1-9][0-9]*$'),

      ]
      ],
      'departmentEmail': ['', [
        Validators.pattern('^[a-z0-9._]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]
      ],
    });

    if (this.data) {
      debugger;
      this.departmentForm.controls['departmentName'].setValue(this.data.departmentName);
      this.departmentForm.controls['departmentEmail'].setValue(this.data.departmentEmail == null ? "" : this.data.departmentEmail.toString());
      this.departmentForm.controls['hod'].setValue(this.data.hod == null ? 0 : this.data.hod.toString());
    }
  }
  // #endregion formControl Validators and Validators Message

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  // #region Edit Dialog Save action
  stopEdit(): void {

  }
  // #endregion

  //#region loads the all DDL data from web api
  public LoadDDLData() {

    this.LoadUsers();
  }
  //#endregion loads the source data from web api

  public LoadUsers() {
    let apiAddress: string = "api/user";
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.users = res as UserModel[];
        this.selectedHods = this.users;
      });
  }


  // #region Get Branch for Edit Dialog Methods
  public LoadDepartment(id: number) {
    let apiAddress: string = "api/department/" + id;

    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.department = res as DepartmentModel;
        this.data = this.department;
        // console.log(this.branch);

      });
  }
  // #endregion Get Branch for Edit Dialog Methods

  // #region Add Dialog Save action
  public updateBranch(): void {
    //debugger;

    //debugger;
    let postmodel: DepartmentModel = {
      id: this.data.id,
      departmentName: this.departmentForm.controls['departmentName'].value,//this.data.departmentName.trim(),
      createdBy: null,
      createdOn: null,
      hod: this.data.hod,
      active: 1,
      modifiedBy: this.loggedUserID,
      modifiedOn: null,
      departmentEmail: this.departmentForm.controls['departmentEmail'].value,
    }
    let apiAddress: string = "api/department/";

    this.repository.update(apiAddress, postmodel)
      .subscribe(res => {

        if (res.responseCode == 'Conflict') {
          //console.log("conflict" + res.responseCode);
          this.showDuplicate();
        }
        else if (res.responseCode == 'OK') {
          this.showUpdateSuccess();
          this.dialogRef.close(res);

        } else if (res.responseCode == 'InternalServerError') {
          this.showError(res.responseMessage);
          this.dialogRef.close(res);
        }

      });
  }
  // #endregion Add Dialog Save action
}
