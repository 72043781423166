import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot  } from '@angular/router';
import { LoginService } from './Services/login.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private login: LoginService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
 //   alert(this.login.isLoggedIn());
    if (!this.login.isLoggedIn())
    {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
       return false;
    }
    else if (this.isTokenExpired()) {
    
      return false;
    }
    return true;
   
  }

  isTokenExpired(): Boolean {
    const timer = JSON.parse(localStorage.getItem('timer'));
  //  alert(timer);
     if (timer && (Date.now() > timer)) {
       this.login.logout();
       this.router.navigate(['/login']);
       return true;
     }
    return false;
  }
}
