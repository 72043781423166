// #region Imports
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogConfig, MatSortable } from '@angular/material';
import { SubCategoryModel } from '../../Models/subcategory-model';
import { RepositoryService } from '../../Services/repository.service';
import { SubAddDialogComponent } from './sub-add-dialog/sub-add-dialog.component';
import { SubEditDialogComponent } from './sub-edit-dialog/sub-edit-dialog.component';
import { SubDeleteDialogComponent } from './sub-delete-dialog/sub-delete-dialog.component';
import { Router } from '@angular/router';
// #endregion

/*
 *  Created by : Ganesh Gurav
 *  Created on : 23-Nov-2018
 *  Description: This component is used for sub category master.
 */

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.css']
})
export class SubCategoryComponent implements OnInit {

  // #region Material Data Table declaration and initialisation

  public dataSource: MatTableDataSource<SubCategoryModel>
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['id', 'categoryName', 'subCategoryName', 'actions'];
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  //Table filter setting

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
 
  ngOnInit() {
    this.loadData();
  }

  private refreshTable() {
    // Refreshing table using paginator
    // Thanks yeager-j for tips
    // https://github.com/marinantonio/angular-mat-table-crud/issues/12
    this.loadData();

    this.paginator._changePageSize(this.paginator.pageSize);


  }
  // #region Variables Declarations
  subCategoryModel: SubCategoryModel[];
  subCategory: SubCategoryModel;
   // #endregion

  //loads the data from web api
  public loadData() {
  
    let apiAddress: string = "api/subCategory";

    this.repository.getData(apiAddress)

      .subscribe(res => {
        this.subCategoryModel = res as SubCategoryModel[];
        this.dataSource = new MatTableDataSource(this.subCategoryModel);
        //reset defualt sort
        //let ortOrder: string = 'asc';
        //this.sort.sort(<MatSortable>({ id: 'categoryName', start: ortOrder }));
        

        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
          if (!data[sortHeaderId]) {
            return this.sort.direction === "asc" ? '3' : '1';
          }
          return '2' + data[sortHeaderId].toLocaleLowerCase();
        };
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        //console.log(this.dataSource);
      });
  }

  // #endregion
  constructor(public dialog: MatDialog,
    private repository: RepositoryService, private router: Router) { }

  // #region Add Dialog Methods

  addNew() {
    //debugger;
    let subCategoryModel: SubCategoryModel;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = { subCategoryModel: subCategoryModel };
    const dialogRef = this.dialog.open(SubAddDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {


      if (result.responseCode == 'OK') {


        this.refreshTable();
      } else {


      }
    });
  }


// #endregion



  // #region Edit Dialog Methods

  selectedRow(row: any): void {
    this.selectedRow = row; //console.log(row);
  }
  startEdit(i: number, id: number, subCategoryName: string) {
    // index row is used just for debugging proposes and can be removed
    let apiAddress: string = "api/subCategory/" + id;
    //debugger;
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.subCategory = res as SubCategoryModel;

        this.openDialog(id, this.subCategory);
      });
  }
  public openDialog(id: number, subCategory: SubCategoryModel) {

    const dialogConfig = new MatDialogConfig();
    //debugger;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = subCategory;
    const dialogRef = this.dialog.open(SubEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.responseCode == 'OK') {
        this.refreshTable();
      } else {
      }
    });
  }

  // #endregion


  // #region Delete Dialog Methods
  deleteItem(i: number, id: number) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = { Id: id };
    const dialogRef = this.dialog.open(SubDeleteDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.responseCode == 'OK') {
        //debugger;
        this.refreshTable();
        //   this.router.navigate(['/login']);
      } else {
      }
    });
  }
  // #endregion Delete Dialog Methods
}
