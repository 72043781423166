
export const menus = [
    {
        'name': 'Dashboard',
        'icon': 'dashboard',
    'link': '/auth/dashboard',
        'open': true,
      
  },
 
  {
    'name': 'Complaints',
    'icon': 'assignment',
    'link': false,
    'open': false,
   // 'chip': { 'value': 1, 'color': 'accent' },
    'sub': [
      {
        'name': 'Add',
        'link': 'complaint/add',
        'icon': 'note_add',
        'chip': false,
        'open': true,
      },
      //{
      //  'name': 'Peding for My Action',
      //  'link': 'complaint/view',
      //  'icon': 'visibility',
      //  'chip': false,
      //  'open': true,
      //},
      {
        'name': 'Check Status',
        'link': 'complaint/checkstatus',
        'icon': 'visibility',
        'chip': false,
        'open': true,
      },
    ]
  },
     
  {
    'name': 'Administration',
    'icon': 'account_box',
    'open': false,
    'link': false,
   // 'chip': { 'value': 6, 'color': 'accent' },
    'sub': [
     
      {
        'name': 'Branch',
        'icon': 'pets',
        'open': false,
        'link': 'administrator/branch',
      },
      {
        'name': 'Department',
        'icon': 'pets',
        'open': false,
        'link': 'administrator/department',
      },
      {
        'name': 'Holiday',
        'icon': 'control_camera',  
        'open': false,
        'link': 'administrator/holiday',
      },
      {
        'name': 'Source', 
        'icon': 'donut_small',
        'open': false,
        'link': 'administrator/source', 
      }, {
        'name': 'Category',
        'icon': 'widgets',
        'open': false,
        'link': 'administrator/category',
      },
      {
        'name': 'Sub Category',
        'icon': 'view_comfy',
        'open': false,
        'link': 'administrator/subcategory',
      },
      {
        'name': 'User',
        'icon': 'view_comfy',
        'open': false,
        'link': 'administrator/user',
      },
      
    ]
  },
{
    'name': 'Reports',
    'icon': 'account_box',
    'open': false,
    'link': false,
   // 'chip': { 'value': 6, 'color': 'accent' },
    'sub': [
     
      {
        'name': 'Complaint Register',
        'icon': 'view_comfy',
        'open': false,
        'link': 'reports/complaintregister',
      },

      {
        'name': 'MIS Report',
        'icon': 'view_comfy',
        'open': false,
        'link': 'reports/mis',
      } 
      
    ]
  }
    
];

export const menusCCE = [
  {
    'name': 'Dashboard',
    'icon': 'dashboard',
    'link': '/auth/dashboard',
    'open': true,
  
  },

  {
    'name': 'Complaints',
    'icon': 'assignment',
    'link': false,
    'open': false,
   // 'chip': { 'value': 1, 'color': 'accent' },
    'sub': [
      {
        'name': 'Add',
        'link': 'complaint/add',
        'icon': 'note_add',
        'chip': false,
        'open': true,
      },
      {
        'name': 'Peding for My Action',
        'link': 'complaint/view',
        'icon': 'visibility',
        'chip': false,
        'open': true,
      },
      {
        'name': 'Check Status',
        'link': 'complaint/checkstatus',
        'icon': 'visibility',
        'chip': false,
        'open': true,
      },
    ]
  },

  {
    'name': 'Administration',
    'icon': 'account_box',
    'open': false,
    'link': false,
    //'chip': { 'value': 6, 'color': 'accent' },
    'sub': [

      {
        'name': 'Branch',
        'icon': 'pets',
        'open': false,
        'link': 'administrator/branch',
      },
      {
        'name': 'Department',
        'icon': 'pets',
        'open': false,
        'link': 'administrator/department',
      },
      {
        'name': 'Holiday',
        'icon': 'control_camera',
        'open': false,
        'link': 'administrator/holiday',
      },
      {
        'name': 'Source',
        'icon': 'donut_small',
        'open': false,
        'link': 'administrator/source',
      }, {
        'name': 'Category',
        'icon': 'widgets',
        'open': false,
        'link': 'administrator/category',
      },
      {
        'name': 'Sub Category',
        'icon': 'view_comfy',
        'open': false,
        'link': 'administrator/subcategory',
      },
      {
        'name': 'User',
        'icon': 'view_comfy',
        'open': false,
        'link': 'administrator/user',
      },

    ]
  }
  ,
  {
    'name': 'Reports',
    'icon': 'account_box',
    'open': false,
    'link': false,
    // 'chip': { 'value': 6, 'color': 'accent' },
    'sub': [

      {
        'name': 'Complaint Register',
        'icon': 'view_comfy',
        'open': false,
        'link': 'reports/complaintregister',
      },

      {
        'name': 'MIS Report',
        'icon': 'view_comfy',
        'open': false,
        'link': 'reports/mis',
      } 

    ]
  }
];
export const menusBM = [
  {
    'name': 'Dashboard',
    'icon': 'dashboard',
    'link': '/auth/dashboard',
    'open': true,
 
  },

  {
    'name': 'Complaints',
    'icon': 'assignment',
    'link': false,
    'open': false,
  //  'chip': { 'value': 1, 'color': 'accent' },
    'sub': [
      //{
      //  'name': 'Add',
      //  'link': 'complaint/add',
      //  'icon': 'note_add',
      //  'chip': false,
      //  'open': true,
      //},
      {
        'name': 'Peding for My Action',
        'link': 'complaint/view',
        'icon': 'visibility',
        'chip': false,
        'open': true,
      },
      {
        'name': 'Check Status',
        'link': 'complaint/checkstatus',
        'icon': 'visibility',
        'chip': false,
        'open': true,
      },
    ]
  },

  

];

