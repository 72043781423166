// #region Imports
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RepositoryService } from '../../../Services/repository.service';
import { ToasterService, Toast, ToasterConfig, BodyOutputType } from 'angular2-toaster';
// #endregion

/*
 *  Created by : Sanjiv Shinde
*   Created on : 21-Nov-2018
 *  Description: This component is used for User Delete dialog.
 *
 *
 * */

@Component({
  selector: 'app-user-delete-dialog',
  templateUrl: './user-delete-dialog.component.html',
  styleUrls: ['./user-delete-dialog.component.css']
})
export class UserDeleteDialogComponent implements OnInit {

  // #region Toaster Service registration and defualt position


  private toasterService: ToasterService;

  public config: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: false,

    positionClass: 'toast-top-center'
  });

  // #endregion

  // #region Toaster  Pop ups for actions


  showDelete(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Delete', responseMessage);
  }
  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }



  // #endregion

  ngOnInit() {
  }


  // #region Constructors and Validators

  constructor(public dialogRef: MatDialogRef<UserDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public repository: RepositoryService,
    toasterService: ToasterService) {
    this.toasterService = toasterService;
  }
  // #endregion

  // #region Delete Dialog  actions
  onNoClick(): void {
    this.dialogRef.close();
  }
  // #endregion

  // #region delete Dialog Save action
  confirmDelete(): void {

    let apiAddress: string = "api/user/" + this.data.Id;
    //console.log("on delete");

    this.repository.delete(apiAddress)
      .subscribe(res => {
       
        if (res.responseCode == 'Conflict') {

        }
        else if (res.responseCode == 'OK') {
          this.showDelete(res.responseMessage);
          this.dialogRef.close(res);

        } else if (res.responseCode == 'InternalServerError') {
          this.showError(res.responseMessage);
          this.dialogRef.close(res);

        }




      });
  }
  // #endregion

}
