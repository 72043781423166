/*
 *  Created by : Ganesh Gurav
*   Created on : 22-Nov-2018
 *  Description: This component is used for sub category add dialog.
 */

// #region Imports
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatAutocompleteModule, MAT_DIALOG_DATA } from '@angular/material';
import { RepositoryService } from '../../../Services/repository.service';
import { SubCategoryModel } from '../../../Models/subCategory-model';
import { CategoryModel } from '../../../Models/category-model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Validators, FormControl } from '@angular/forms';
import { ToasterService, Toast, ToasterConfig, BodyOutputType } from 'angular2-toaster';
// #endregion


@Component({
  selector: 'app-sub-add-dialog',
  templateUrl: './sub-add-dialog.component.html',
  styleUrls: ['./sub-add-dialog.component.css']
})

export class SubAddDialogComponent implements OnInit {


  // #region Variables Declarations
  public loggedUserID: string;
  categories: CategoryModel[];
  // #endregion Variables Declarations

  // #region Toaster Service registration and defualt position
  private toasterService: ToasterService;
  public config: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: false,
    positionClass: 'toast-top-center'
  });
  // #endregion

  filteredOptions: Observable<CategoryModel[]>;

  // #region Constructors
  constructor(public dialogRef: MatDialogRef<SubAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SubCategoryModel,
    private repository: RepositoryService,
    toasterService: ToasterService, MatAutocompleteModul: MatAutocompleteModule) {
    this.toasterService = toasterService;
  }
  // #endregion Constructors and Validators

  // #region Toaster  Pop ups for actions
  showDuplicate() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'Delete', 'Duplicate Record!');
  }

  showSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Created!');
  }

  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }
  // #endregion Toaster  Pop ups for actions


  // #region  formControl Validators and Validators Message
  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);


  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
        '';
  }
  // #endregion formControl Validators and Validators Message


  submit() {
    // emppty stuff
  }

  displayFn(category?: CategoryModel): string | undefined {
    return category ? category.categoryName : undefined;
  }

  private _filter(name: string): CategoryModel[] {
    const filterValue = name.toLowerCase();

    return this.categories.filter(option => option.categoryName.toLowerCase().indexOf(filterValue) === 0);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirmAdd(): void {
    //debugger;
    let postSubCategorymodel: SubCategoryModel = {
      id: 0,
      subCategoryName: this.data.subCategoryName.trim(),
      categoryId: this.data.categoryId,
      active: 1,
      createdBy: this.loggedUserID,
      createdOn: null,
      modifiedBy: null,
      modifiedOn: null
    }
    let apiAddress: string = "api/subCategory/post";

    this.repository.createResponse(apiAddress, postSubCategorymodel)
      .subscribe(res => {

        if (res.responseCode == 'Conflict') {
          //console.log("conflict" + res.responseCode);
          this.showDuplicate();
        }
        else if (res.responseCode == 'OK') {
          this.showSuccess();
          this.dialogRef.close(res);

        } else if (res.responseCode == 'InternalServerError') {
          this.showError(res.responseMessage);
          this.dialogRef.close(res);

        }

      });
  }

  ngOnInit() {
    this.LoadCategory();
    this.loggedUserID = localStorage.getItem('userId');
  }

  //#region loads category data from web api
  public LoadCategory() {

    let apiAddress: string = "api/category";
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.categories = res as CategoryModel[];
        console.log(this.categories);
        this.filteredOptions = this.formControl.valueChanges
          .pipe(
          startWith<string | CategoryModel>(''),
            map(value => typeof value === 'string' ? value : value.categoryName),
          map(name => name ? this._filter(name) : this.categories.slice())
          );
      });
  }
  //#endregion loads region data from web api

}
