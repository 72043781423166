import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component } from '@angular/core';

@Component({
  selector: 'app-toaster',
  templateUrl: 'toaster.component.html',
})
export class ToasterComponent {
  times: number[] = [];
  constructor() {
    for (let index = 0; index < 5; index++) {
      this.times.push(index);
    }
  }
}

@NgModule({
  imports: [BrowserModule],
  bootstrap: [ToasterComponent],
  declarations: [ToasterComponent]
})
export class ToasterComponentModule { }
