/*
 *  Created by : Sanjiv Shinde
*   Created on : 21-Nov-2018
 *  Description: This component is used for add user master.
 * */

// #region Imports


import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatAutocompleteModule, MatSelect, MAT_DIALOG_DATA } from '@angular/material';
import { RepositoryService } from '../../../Services/repository.service';
import { Validators, FormControl, ReactiveFormsModule, FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { ToasterService, Toast, ToasterConfig, BodyOutputType } from 'angular2-toaster';

import { RegionModel } from '../../../Models/region-model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserModel } from '../../../Models/user-model';
import { RoleModel } from '../../../Models/role-model';
import { BranchModel } from 'src/app/Models/branch-model';

// #endregion

@Component({
  selector: 'app-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.css']
})
export class UserEditDialogComponent implements OnInit {

  // #region Variables Declarations
  public loggedUserID: string;
  addUserForm: FormGroup;
  roleModel: RoleModel[];
  public cancelButtonClicked: boolean = false;
  branchModel: BranchModel[];
  selectedBranches: BranchModel[];
  data: UserModel;
  // #endregion Variables Declarations

  // #region Toaster Service registration and defualt position
  private toasterService: ToasterService;


  public toasterConfig: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: true,
    timeout: 0,

    positionClass: 'toast-top-right'
  });
  // #endregion

  // #region Toaster  Pop ups for actions
  showDuplicate(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'Duplicate', responseMessage);
  }

  showSuccess(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', responseMessage);
  }

  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }
  // #endregion Toaster  Pop ups for actions


  // #region Constructors
  constructor(public dialogRef: MatDialogRef<UserEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public userData: any,
    private repository: RepositoryService,
    toasterService: ToasterService, MatAutocompleteModul: MatAutocompleteModule, private fb: FormBuilder) {
    this.toasterService = toasterService;
    this.selectedBranches = this.userData.branch;
    this.data = this.userData.user;
    this.LoadRole();
//    this.bindBranchDDL();
    //this.addUserForm.setValue(
    //  {
    //    employeeCode: this.data.employeeCode,
    //    firstName: this.data.firstName,
    //    lastName: this.data.lastName,
    //    email: this.data.email,
    //    phoneNumber: this.data.phoneNumber,

    //  });


    this.buildForm();
    this.loggedUserID = localStorage.getItem('userId');
  }
  // #endregion Constructors and Validators

  ngOnInit() {
    
    
    //  this.addUserForm.setValue(this.data);
    
   
  }

  //#region loads Roles from web api
  public LoadRole() {

    let apiAddress: string = "api/user/GetAllRoles";
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.roleModel = res as RoleModel[];


      });
  }
  //#endregion  
  //#region Bind Dropdown List


  bindBranchDDL() {
    let apiAddress: string = "api/branch/";

    this.repository.getData(apiAddress)

      .subscribe(res => {
        this.branchModel = res as BranchModel[];
        this.selectedBranches = this.branchModel;
        this.buildForm();
        //this.addUserForm.get('branchId').setValue(this.data.branchId);
        //console.log(this.dataSource);
      });
  }

  // #region  formControl Validators and Validators Message



  formErrors = {
    'employeeCode': '',
    'firstName': '',
    'lastName': '',
    'email': '',
    'phoneNumber': '',
    'id': '',
    'userName': '',
    'roleName': ''
  };
  validationMessages = {
    'employeeCode': {
      'required': 'Please enter your employee code'
    },
    'firstName': {
      'required': 'Please enter your first name'
    },
    'lastName': {
      'required': 'Please enter your last name',

    },
    'email': {
      'required': 'Please enter valid email',
      'pattern': 'Please enter valid email'
    },
    'phoneNumber': {
      'required': 'Please enter mobile no.',

    },
    'userName': {
      'required': 'Please enter user name',

    },
    
    'roleName': {
      'required': 'Please select role',

    },
    //'password': {
    //  'required': 'Please enter password',
    //  'minlength': 'Please enter more than 6 characters',
    //  'maxlength': 'Please enter less than 25 characters',
    //},
  };

  // #endregion

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.cancelButtonClicked = true;
    this.dialogRef.close();
  }

  // #region Create initial form

  ngAfterViewInit() {


  }


  buildForm() {



    this.addUserForm = this.fb.group({
      'id': [this.data.id, [
        // Validators.required
      ]
      ], 'employeeCode': [this.data.employeeCode, [
        Validators.required
      ]
      ],
      'firstName': [this.data.firstName, [
        Validators.required
      ]
      ]
      ,
      'lastName': [this.data.lastName, [
       // Validators.required
      ]
      ],

      'roleName': [this.data.roleName, [
        Validators.required
      ]
      ],
      'email': [this.data.email, [
        // Validators.required,
        Validators.pattern('^[a-z0-9._]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]
      ],
      'phoneNumber': [this.data.phoneNumber, [
       // Validators.required
      ]
      ],
       'userName': [this.data.userName, [
        Validators.required
      ]
      ],
      'branchId': [this.data.branchId==null?'':this.data.branchId.toString(), [
        //Validators.required
      ]
      ],



    });




    // #endregion

    // #region Value changes event



    this.addUserForm.valueChanges.subscribe(
      data => this.onValueChanged(data)

    );
    this.onValueChanged();
  }
  onValueChanged(data?: any) {

    if (!this.addUserForm) {
      return;
    }
    const form = this.addUserForm;

    for (const field in this.formErrors) {

      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }
    //#endregion

    // #region Edit Dialog Save action
  public saveUser(form: NgForm): void {
    //check cancelButtonClicked clicked

    if (this.cancelButtonClicked) { return; }
    else {

      // stop here if form is invalid
      if (this.addUserForm.invalid) {
        return;
      }
      const result1 = JSON.parse(JSON.stringify(form));


      let apiAddress: string = "api/user/";

      this.repository.update(apiAddress, result1)
        .subscribe(res => {

          if (res.responseCode == 'DuplicateUserName') {
            //console.log("conflict" + res.responseCode);
            this.showDuplicate(res.responseMessage);
          }
          else if (res.responseCode == 'OK') {
            this.showSuccess(res.responseMessage);
            this.dialogRef.close(res);

          } else if (res.responseCode == 'InternalServerError') {
            this.showError(res.responseMessage);
            this.dialogRef.close(res);
          }

        });
    }
  }
  // #endregion Add Dialog Save action



}
