/*
 *  Created by : Sanjiv Shinde
*   Created on : 21-Nov-2018
 *  Description: This component is used for user master.
 * */


// #region Imports
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserModel } from '../../Models/user-model'
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatDialogConfig, MatSortable } from '@angular/material';
import { RepositoryService } from '../../Services/repository.service';
import { Router } from '@angular/router';
import { UserAddDialogComponent } from './user-add-dialog/user-add-dialog.component';
import { UserEditDialogComponent } from './user-edit-dialog/user-edit-dialog.component';
import { UserDeleteDialogComponent } from './user-delete-dialog/user-delete-dialog.component';
import { BranchModel } from 'src/app/Models/branch-model';

// #endregion Imports
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {



  // #region Variables Declarations
  userModel: UserModel[];
  user: UserModel;
  loading: boolean = true;
  branchModel: BranchModel[];
  
  // #endregion Variables Declarations

  // #region Material Data Table for source master, declaration and initialisation
  public dataSource: MatTableDataSource<UserModel>
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['id', 'userName','employeeCode','firstName', 'email','phoneNumber', 'actions'];
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  // #endregion Material Data Table declaration and initialisation
  constructor(public dialog: MatDialog, private repository: RepositoryService, private router: Router) { }

  ngOnInit() {

    this.loadData();
    this.bindBranchDDL();
    // make default sort
      let ortOrder: string = 'asc';
    this.sort.sort(<MatSortable>({ id: 'firstName', start: ortOrder }));
   // this.dataSource.sort = this.sort;

  }

  //#region loads the source data from web api
  public loadData() {

    let apiAddress: string = "api/user";
    this.loading = true;
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.userModel = res as UserModel[];
        this.dataSource = new MatTableDataSource(this.userModel);
        this.dataSource.paginator = this.paginator;
        // set start as desc then make asc
       // let ortOrder: string = 'asc';
        //this.sort.sort(<MatSortable>({ id: 'firstName', start: ortOrder }));


        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
          if (!data[sortHeaderId]) {
            return this.sort.direction === "asc" ? '3' : '1';
          }
          return '2' + data[sortHeaderId].toLocaleLowerCase();
        };

        this.dataSource.sort = this.sort;
        //this.dataBranch.data = res;
        this.loading = false;
      });
  

    
  }
  //#endregion loads the source data from web api

  // #region Table filter setting
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  // #endregion Table filter setting

  // #region refresh table
  private refreshTable() {
    // Refreshing table using paginator
    // Thanks yeager-j for tips
    // https://github.com/marinantonio/angular-mat-table-crud/issues/12
    this.loadData();
    this.paginator._changePageSize(this.paginator.pageSize);
  }
  // #endregion refresh table


  // #region Add Dialog Methods
  addNew() {
    let userModel: UserModel;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = { userModel: userModel };
    const dialogRef = this.dialog.open(UserAddDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {

        this.refreshTable();
      } else if (result.responseCode == 'OK') {

        this.refreshTable();


      } else {

      }
    });
  }
  // #endregion Add Dialog Methods]


  // #region Edit Dialog Methods
  selectedRow(row: any): void {
    this.selectedRow = row; //console.log(row);
  }
  startEdit(i: number, id: number) {
    // index row is used just for debugging proposes and can be removed
    let apiAddress: string = "api/user/" + id;
    //debugger;
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.user = res as UserModel;

        this.openDialog(id, this.user);
      });
  }
  public openDialog(id: number, userModel: UserModel) {

    const dialogConfig = new MatDialogConfig();
   
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
     user:this.user,branch: this.branchModel
    };
    const dialogRef = this.dialog.open(UserEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


      if (result == undefined) {

        this.refreshTable();
      } else if (result.responseCode == 'OK') {

        this.refreshTable();


      } else {

      }
    });
  }


  //#region Bind Dropdown List


  bindBranchDDL() {
    let apiAddress: string = "api/branch/";

    this.repository.getData(apiAddress)

      .subscribe(res => {
        this.branchModel = res as BranchModel[];
       
        //this.addUserForm.get('branchId').setValue(this.data.branchId);
        //console.log(this.dataSource);
      });
  }

  // #endregion  Edit Dialog Methods


  // #region Delete Dialog Methods
  deleteItem(i: number, id: number) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = { Id: id };
    const dialogRef = this.dialog.open(UserDeleteDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


      if (result == undefined) {

        this.refreshTable();
      } else if (result.responseCode == 'OK') {

        this.refreshTable();


      } else {

      }
    });
  }
  // #endregion Delete Dialog Methods

}
