/*
 *  Created by : Vishal Mahajan
*   Created on : 02-Nov-2018
 *  Description: This component is used for source master.
 * */


// #region Imports
import { Component, OnInit, ViewChild } from '@angular/core';
import { SourceModel } from '../../Models/source-model'
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatDialogConfig, MatSortable } from '@angular/material';
import { RepositoryService } from '../../Services/repository.service';
import { Router } from '@angular/router';
import { SourceDeleteDialogComponent } from './source-delete-dialog/source-delete-dialog.component';
import { SourceAddDialogComponent } from './source-add-dialog/source-add-dialog.component';
import { SourceEditDialogComponent } from './source-edit-dialog/source-edit-dialog.component';
// #endregion Imports

@Component({
  selector: 'app-source',
  templateUrl: './source.component.html',
  styleUrls: ['./source.component.css']
})
export class SourceComponent implements OnInit {
  // #region Variables Declarations
  sourceModel: SourceModel[];
  // #endregion Variables Declarations

   // #region Material Data Table for source master, declaration and initialisation
  public dataSource: MatTableDataSource<SourceModel>
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['id', 'sourceName', 'actions'];
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
   // #endregion Material Data Table declaration and initialisation

  constructor(public dialog: MatDialog, private repository: RepositoryService, private router: Router ) { }

  ngOnInit() {
    this.loadData();


  }

  //#region loads the source data from web api
  public loadData() {

    let apiAddress: string = "api/source";

    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.sourceModel = res as SourceModel[];
        this.dataSource = new MatTableDataSource(this.sourceModel);
        this.dataSource.paginator = this.paginator;

        //reset defaultsort
        //let ortOrder: string = 'asc';
        //this.sort.sort(<MatSortable>({ id: 'sourceName', start: ortOrder }));


        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
          if (!data[sortHeaderId]) {
            return this.sort.direction === "asc" ? '3' : '1';
          }
          return '2' + data[sortHeaderId].toLocaleLowerCase();
        };

        this.dataSource.sort = this.sort;

    //  this.dataSource.sort = this.sort;
       
      }); 
  }
   //#endregion loads the source data from web api

  // #region Table filter setting

  //Table filter setting

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // #endregion Table filter setting


  // #region refresh table
  private refreshTable() {
    // Refreshing table using paginator
    // Thanks yeager-j for tips
    // https://github.com/marinantonio/angular-mat-table-crud/issues/12
    this.loadData();
    this.paginator._changePageSize(this.paginator.pageSize);
  }
  // #endregion refresh table


  // #region Add Dialog Methods
  addNew() {
    let sourceModel: SourceModel
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = { sourceModel: sourceModel };
    const dialogRef = this.dialog.open(SourceAddDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.responseCode == 'OK') {
        this.refreshTable();
      } else {
      }
    });
  }
  // #endregion Add Dialog Methods]


  // #region Edit Dialog Methods
  selectedRow(row: any): void {
    this.selectedRow = row; //console.log(row);
  }
  startEdit(i: number, id: number, sourceName: string) { 
    // index row is used just for debugging proposes and can be removed
    const dialogConfig = new MatDialogConfig();
    //debugger;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = { id: id, sourceName: sourceName };
    //console.log("before edit");
    //console.log(dialogConfig.data);
    const dialogRef = this.dialog.open(SourceEditDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.responseCode == 'OK') {
        this.refreshTable();
      } else {
      }
    });
  }
  // #endregion  Edit Dialog Methods



  // #region Delete Dialog Methods
  deleteItem(i: number, id: number) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = { Id: id };
    const dialogRef = this.dialog.open(SourceDeleteDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.responseCode == 'OK') {
        this.refreshTable();
        //   this.router.navigate(['/login']);
      } else {
      }
    });
  }
  // #endregion Delete Dialog Methods
}
