/*
 *  Created by : Vishal Mahajan
*   Created on : 02-Nov-2018
 *  Description: This component is used for add branch master.
 * */

// #region Imports
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatAutocompleteModule, MatSelect, MAT_DIALOG_DATA, VERSION } from '@angular/material';
import { RepositoryService } from '../../../Services/repository.service';
import { Validators, FormControl, ReactiveFormsModule, FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { ToasterService, Toast, ToasterConfig, BodyOutputType } from 'angular2-toaster';
import { BranchModel } from '../../../Models/branch-model';
import { RegionModel } from '../../../Models/region-model';
import { Observable } from 'rxjs';
import { map, startWith, takeUntil, take } from 'rxjs/operators';
import { CountryModel } from '../../../Models/country-model';
import { StateModel } from '../../../Models/state-model';
import { UserModel } from '../../../Models/user-model';
import { BranchCustomerCareRelationModel } from 'src/app/Models/branchCustomerCareRelation-model';
// #endregion

@Component({
  selector: 'app-branch-add-dialog',
  templateUrl: './branch-add-dialog.component.html',
  styleUrls: ['./branch-add-dialog.component.css']
})
export class BranchAddDialogComponent implements OnInit {

  // #region Variables Declarations
  public loggedUserID: string;
  regions: RegionModel[];
  users: UserModel[];
  selectedBMs: UserModel[];
  countries: CountryModel[];
  states: StateModel[];

  addBranchForm: FormGroup;
  branchCustomerExecutives: BranchCustomerCareRelationModel[];
  // #endregion Variables Declarations

  // #region Toaster Service registration and defualt position
  private toasterService: ToasterService;
  public config: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: false,
    positionClass: 'toast-top-center'
  });
  // #endregion

  filteredOptions: Observable<RegionModel[]>;

  // #region Constructors
  constructor(public dialogRef: MatDialogRef<BranchAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BranchModel,
    private repository: RepositoryService,
    toasterService: ToasterService, MatAutocompleteModul: MatAutocompleteModule, private fb: FormBuilder) {
    this.toasterService = toasterService;
  }
  // #endregion Constructors and Validators

  // #region BM search

  bmSearch(query: string) {
    //debugger;
    const result = this.selectBMs(query);
    this.selectedBMs = result;
  }

  selectBMs(query: string): UserModel[] {

     
    const result: UserModel[] = [];
    for (const roleObj of this.users) {
      if ((roleObj.firstName +' '+ roleObj.lastName).toLowerCase().indexOf(query) > -1) {
        result.push(roleObj);
      }
    }
    return result;
  }
  // #endregion

  // #region Toaster  Pop ups for actions
  showDuplicate() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'Delete', 'Duplicate Record!');
  }

  showSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Created!');
  }

  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }
  // #endregion Toaster  Pop ups for actions

  //   formControl Validators and Validators Message
  //formControl = new FormControl('', [
  //  Validators.required,
  //   Validators.email,
  //]);


  //getErrorMessage() {
  //  return this.formControl.hasError('required') ? 'Required field' :
  //    this.formControl.hasError('email') ? 'Not a valid email' :
  //      '';
  //}
  //   formControl Validators and Validators Message


  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  displayFn(region?: RegionModel): string | undefined {
    return region ? region.regionName : undefined;
  }

  private _filter(name: string): RegionModel[] {
    const filterValue = name.toLowerCase();

    return this.regions.filter(option => option.regionName.toLowerCase().indexOf(filterValue) === 0);
  }

  // #region Add Dialog Save action
  public confirmAdd(form: NgForm): void {



    // stop here if form is invalid
    if (this.addBranchForm.invalid) {
      return;
    }
    const result1 = JSON.parse(JSON.stringify(form));

     
 
   // this.getAllSelectedBranchCustomerExecutive();
    let postmodel: BranchModel = {
      id: 0,
      branchName: result1.branchName,
      regionId: result1.regionId,
      branchCode: result1.branchCode,
      address1: result1.address1,
      address2: result1.address2,
      address3: result1.address3,
      state: result1.state,
      city: result1.city,
      pin: result1.pin,
      phoneNo1: result1.phoneNo1,
      phoneNo2: result1.phoneNo2,
      email: result1.email,
      branchManagerId: result1.branchManagerId,
      asstBranchManagerId: result1.asstBranchManagerId == '' ? null : result1.asstBranchManagerId,
      //deputyGmid: result1.deputyGmid,
      //asstGmid: result1.asstGmid,
      //jointGmid: result1.jointGmid,
      active: 1,
      createdBy: this.loggedUserID,
      createdOn: null,
      modifiedBy: null,
      modifiedOn: null,
     // branchCustomerCareRelation: this.branchCustomerExecutives
    }
    let apiAddress: string = "api/branch/";
    var test: string = "test string";
    this.repository.createResponse(apiAddress, postmodel)
      .subscribe(res => {

        if (res.responseCode == 'Conflict') {
          //console.log("conflict" + res.responseCode);
          this.showDuplicate();
        }
        else if (res.responseCode == 'OK') {
          this.showSuccess();
          this.dialogRef.close(res);

        } else if (res.responseCode == 'InternalServerError') {
          this.showError(res.responseMessage);
          this.dialogRef.close(res);
        }

      });
  }
  // #endregion Add Dialog Save action

  ngOnInit() {
    this.buildForm();
    this.LoadDDLData();
    this.loggedUserID = localStorage.getItem('userId');
  }

  //#region Build Form and validation
  buildForm() {



    this.addBranchForm = this.fb.group({
      'branchCode': ['', [
        Validators.required,
        Validators.pattern('^[0-9]{1,3}'),
      ]
      ],
      'branchName': ['', [
        Validators.required
      ]
      ],
      'address1': ['', [
        Validators.required
      ]
      ]
      ,
      'address2': ['', [
        // Validators.required
      ]
      ],
      'email': ['', [
        // Validators.required,
        Validators.pattern('^[a-z0-9._]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]
      ],
      'address3': ['', [
        // Validators.required
      ]
      ],
      'regionId': ['', [
        Validators.required,
        Validators.pattern('^[1-9][0-9]*$'),
      ]
      ],

      'state': ['', [
      
      ]
      ],
      'city': ['', [
     
      ]
      ],
      'pin': ['', [
        Validators.pattern('^[0-9]{6}'),
      ]
      ],
      'phoneNo1': ['', [
        Validators.pattern('^[0-9]{0,10}'),
      ]
      ],
      'phoneNo2': ['', [
        Validators.pattern('^[0-9]{0,10}'),
      ]
      ],
      'branchManagerId': ['', [
        Validators.required,
        //Validators.pattern('^[1-9][0-9]*$'),
      ]
      ],
      'asstBranchManagerId': ['', [
        //Validators.required
      ]
      ],



    });

    this.addBranchForm.valueChanges.subscribe(
      data => this.onValueChanged(data)

    );
    this.onValueChanged();
  }


  formErrors = {
    'branchCode': '',
    'branchName': '',
    'address1': '',
    'email': '',
    'regionId': '',
    'branchManagerId': '',
    'phoneNo1': '',
    'phoneNo2': '',
    'pin': '',
  
  };
  validationMessages = {
    'branchCode': {
      'required': 'Please enter branch code',
      'pattern': 'Please enter only number and max. lenght is 3'
    },
    'branchName': {
      'required': 'Please enter branch name'
    },
    'address1': {
      'required': 'Please enter address1',

    },
    'email': {
         'pattern': 'Please enter valid email'
    },
    'regionId': {
      'required': 'Please enter region',
      'pattern': 'Please enter region',
      
    },
    'pin': {
      'pattern': 'Please enter valid pin'
    },
    'branchManagerId': {
      'required': 'Please select branch manager',
      'pattern': 'Please select branch manager',

    },
    'userName': {
      'required': 'Please enter user name',

    },
    'phoneNo1': {
      'pattern': 'Please enter valid contact no. and max length is 10'
    },
    'phoneNo2': {
      'pattern': 'Please enter valid other contact no. and max length is 10'
    },
  };

  onValueChanged(data?: any) {

    if (!this.addBranchForm) {
      return;
    }
    const form = this.addBranchForm;

    for (const field in this.formErrors) {

      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }
 //#endregion


  //#region loads the all DDL data from web api
  public LoadDDLData() {
    this.LoadRegion();
    this.LoadCountry();
    this.LoadState();
    this.LoadUsers();
  }
  //#endregion loads the source data from web api

  //#region loads region data from web api
  public LoadRegion() {

    let apiAddress: string = "api/region";
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.regions = res as RegionModel[];
   
      });
  }
  //#endregion loads region data from web api

  //#region loads user data from web api
  public LoadUsers() {
    let apiAddress: string = "api/user";
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.users = res as UserModel[];
        this.selectedBMs = this.users;
        
        //console.log(this.users);
      });
  }
  //#endregion loads user data from web api

  //#region loads country data from web api
  public LoadCountry() {

    let apiAddress: string = "api/country";
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.countries = res as CountryModel[];
       // console.log(this.countries);
      });
  }
  //#endregion loads country data from web api

  //#region loads state data from web api
  public LoadState() {

    let apiAddress: string = "api/state";
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.states = res as StateModel[];
        //console.log(this.states);
      });
  }
  //#endregion loads state data from web api

  //#region get all selected branch relation executives
  changeBranchCustomerExecutive(data) {
    //debugger;
    //this.branchCustomerExecutives = data.map(value => ({
    //  cced: value,
    //  active: 1,
    //  createdBy: this.loggedUserID
    //}));
    this.branchCustomerExecutives = data.map(value => ({
      cced: value.id,
      active: 1,
      createdBy: this.loggedUserID
    }));
  }
  //#endregion get all selected branch relation executives


}
