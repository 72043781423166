import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { RepositoryService } from '../Services/repository.service';
import { CategoryComponent } from './category/category.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { BranchComponent } from './branch/branch.component';
import { HolidayComponent } from './holiday/holiday.component';
import { SourceComponent } from './source/source.component';
import { UserComponent } from './user/user.component';
import { AuthGuard } from '../auth.guard';
import { DepartmentComponent } from './department/department.component';


const pagesRoutes: Routes = [
  { path: 'branch', canActivate: [AuthGuard], component: BranchComponent, data: { animation: 'branch' } },
  { path: 'department', canActivate: [AuthGuard], component: DepartmentComponent, data: { animation: 'department' } },
  { path: 'holiday', canActivate: [AuthGuard], component: HolidayComponent, data: { animation: 'holiday' } },
  { path: 'source', canActivate: [AuthGuard],component: SourceComponent, data: { animation: 'source' } },
  { path: 'category', canActivate: [AuthGuard],component: CategoryComponent, data: { animation: 'category' } },
  { path: 'subcategory', canActivate: [AuthGuard],component: SubCategoryComponent, data: { animation: 'subcategory' } },
  { path: 'user', canActivate: [AuthGuard],component: UserComponent, data: { animation: 'user' } },
];

@NgModule({
  imports: [
    RouterModule.forChild(pagesRoutes),

  ],
  providers: [RepositoryService],
  exports: [
    RouterModule
  ]
})
export class administratorRouterModule { }
