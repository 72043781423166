import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
 
import { Observable } from 'rxjs/Rx';
import { BehaviorSubject } from 'rxjs/Rx'; 
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { loginAuth } from '../Models/Credentials-model';
import { UserModel } from '../Models/user-model';
import { LoggedUserRoleModel } from '../Models/loggedUserRole-model';
import { ResponseModel } from '../Models/response-model';
 
@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseService {
  envUrl: any;
  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  loggedUserRoleModel: LoggedUserRoleModel[];
  loginUserName = new BehaviorSubject<string>("");
  logedUserActualRole = new BehaviorSubject<string>("");
  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable();

  private loggedIn = false;
  private status = false;




  public getLogedUserName(): Observable<string> {

    var userModel: UserModel = JSON.parse(localStorage.getItem('loggedUser'));
    if (userModel != null) {
      this.loginUserName.next(userModel.firstName + " " + userModel.lastName);
    }
    return this.loginUserName.asObservable();
  }
  public getLogedUserActualRole(): Observable<string> {

    var userRole = (localStorage.getItem('loggedUserActualRole'));
    if (userRole != null) { this.logedUserActualRole.next(userRole); }
    return this.logedUserActualRole.asObservable();
  }
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super();
    this.loggedIn = !!localStorage.getItem('auth_token');
    // ?? not sure if this the best way to broadcast the status but seems to resolve issue on page refresh where auth status is lost in
    // header component resulting in authed user nav links disappearing despite the fact user is still logged in
    this._authNavStatusSource.next(this.loggedIn);
    this.envUrl = baseUrl;
  }

  getUserDetails(route: string) {
    return this.http.get(this.createCompleteRoute(route, this.envUrl))
      .map(res => {
        const userModel: UserModel = JSON.parse(JSON.stringify(res));
        return userModel;
      });

  }

  transform(categories: any, searchText: any): any {
    if (searchText == null) return categories;

    return this.loggedUserRoleModel.filter(function (category) {
      return category.roleName.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    })
  }
  login(route: string, body) {
    //debugger;
     let obj: any;
     //5 * 60 * 1000; // 5 minutes in milliseconds
     var time_to_login = Date.now();
     localStorage.removeItem('auth_token');
     localStorage.removeItem('loggedUser');
     localStorage.removeItem('loggedUserRoles');
     localStorage.removeItem('userId');
     localStorage.removeItem('timer');
     return this.http.post(this.createCompleteRoute(route, this.envUrl), body, this.generateHeaders())
       .map(res => {
         const authmodel: loginAuth = JSON.parse(JSON.stringify(res));
         console.log(authmodel.auth_token);
         localStorage.setItem('auth_token', authmodel.auth_token);
        
         localStorage.setItem('userId', authmodel.id);
         this.loggedIn = true;
         time_to_login = time_to_login + (parseInt( authmodel.expires_in) * 1000);

        
         localStorage.setItem('timer', JSON.stringify(time_to_login));
         this._authNavStatusSource.next(true);



         // do login herere

         let apiAddress: string = "api/user/" + localStorage["userId"];
         this.getUserDetails(apiAddress)
           .subscribe(
             res => {

               const userModel: UserModel = JSON.parse(JSON.stringify(res));

               localStorage.setItem('loggedUser', JSON.stringify(userModel));
               this.loginUserName.next(userModel.firstName + " " + userModel.lastName);

             });

         //get logged user role name
         let apiAddress1: string = "api/user/GetLoggedUserRoles/" + localStorage["userId"];
         this.getUserDetails(apiAddress1)
           .subscribe(
             res => {

               this.loggedUserRoleModel = JSON.parse(JSON.stringify(res));


               localStorage.setItem('loggedUserRoles', JSON.stringify(this.loggedUserRoleModel));
               //console.log(localStorage.getItem('loggedUserRoles'));

               //check user has more than 1 roles then take highest role
               //  Role CCE -> Level1
               //Role JGM - > Level2
               // Role DJGM -> Level3
               // Role BM->Level4
               //
               // Role admin -> Level5
               //  this.roleName = 'CCE';
               if (this.loggedUserRoleModel != null) {
                 if (this.loggedUserRoleModel.length > 1) {
                   if (this.transform(this.loggedUserRoleModel, 'CCE').length > 0) {
                     localStorage.setItem('loggedUserActualRole', 'CCE');
                     this.logedUserActualRole.next('CCE');
                   }
                   else if (this.transform(this.loggedUserRoleModel, 'jgm').length > 0) {
                     localStorage.setItem('loggedUserActualRole', 'JGM');
                     this.logedUserActualRole.next('JGM');

                   } else if (this.transform(this.loggedUserRoleModel, 'DGM').length > 0) {
                     localStorage.setItem('loggedUserActualRole', 'DGM');
                     this.logedUserActualRole.next('DGM');

                   } else if (this.transform(this.loggedUserRoleModel, 'BM').length > 0) {
                     localStorage.setItem('loggedUserActualRole', 'BM');
                     this.logedUserActualRole.next('BM');

                   } else if (this.transform(this.loggedUserRoleModel, 'HOD').length > 0) {
                     localStorage.setItem('loggedUserActualRole', 'HOD');
                     this.logedUserActualRole.next('HOD');

                   } else if (this.transform(this.loggedUserRoleModel, 'Admin').length > 0) {
                     localStorage.setItem('loggedUserActualRole', 'Admin');
                     this.logedUserActualRole.next('Admin');
                   }
                 } else {
                   localStorage.setItem('loggedUserActualRole', this.loggedUserRoleModel[0].roleName); 
                   this.logedUserActualRole.next(this.loggedUserRoleModel[0].roleName);
                 }
               }

            

             });


      return true;

      },
       error => {
     
      this.handleError;
      return false;
        });
   
  
  }

  logout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('loggedUser');
    localStorage.removeItem('loggedUserRoles');
    localStorage.removeItem('userId');
    localStorage.removeItem('timer');
    this.loggedIn = false;
    this._authNavStatusSource.next(false);
  }


  isLoggedIn() {
    return this.loggedIn;
  }
  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}${route}`;
  }
  private generateHeaders() {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }

  public changePassword(route: string, body) {
    //debugger;
    //console.log("in update service");
    return this.http.put<ResponseModel>(this.createCompleteRoute(route, this.envUrl), body, this.generateHeaders());
  }

  public resetPassword(route: string, body) {
    //debugger;
    
    return this.http.put<ResponseModel>(this.createCompleteRoute(route, this.envUrl), body, this.generateHeaders());
  }
}
