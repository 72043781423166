import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatAutocompleteModule, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Validators, FormControl, NgForm, FormGroup, Form, FormBuilder } from '@angular/forms';
import { ToasterService, Toast, ToasterConfig, BodyOutputType } from 'angular2-toaster';
import { LoginService } from '../../../Services/login.service';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.css']
})
export class ForgotPasswordDialogComponent implements OnInit {

  resetPasswordForm: FormGroup;
  errorMessage: string = '';

  // #region Toaster Service registration and defualt position
  private toasterService: ToasterService;
  public config: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: false,
    positionClass: 'toast-top-center'
  });
  // #endregion

  // #region Toaster  Pop ups for actions
  showResetPasswordSuccess() {

    this.toasterService.pop('success', 'Success', 'Password reset successfully!');
  }
  showPasswordMismatch() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'Error', 'Password Mismatch!');
  }
  showSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Created!');
  }
  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }
  // #endregion Toaster  Pop ups for actions

  // #region Constructors
  constructor(public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    toasterService: ToasterService, MatAutocompleteModul: MatAutocompleteModule, private loginservice: LoginService, private fb: FormBuilder) {
    this.toasterService = toasterService;
  }
  // #endregion Constructors and Validators

  userName = new FormControl();

  formErrors = {

    'userName': ''

  };
  validationMessages = {

    'userName': {
      'required': 'Please enter password',
    },

  };

  buildForm() {

    this.resetPasswordForm = this.fb.group({

      'userName': ['', [
        Validators.required,
      ]
      ],

    }
    );

    // #endregion


    this.resetPasswordForm.valueChanges.subscribe(
      data => this.onValueChanged(data)

    );
    this.onValueChanged();
  }

  onValueChanged(data?: any) {

    if (!this.resetPasswordForm) {
      return;
    }
    const form = this.resetPasswordForm;
    //debugger;
    for (const field in this.formErrors) {
      //debugger;
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        //debugger;
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  // #region  formControl Validators and Validators Message
  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);


  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      '';
  }
  // #endregion formControl Validators and Validators Message

  ngOnInit() {
    this.buildForm();
  }

  submit() {
    // emppty stuff
  }

  // #region reset Password action
  public resetPassword(): void {
    //debugger;
    let userName = this.resetPasswordForm.controls["userName"].value;
    let apiAddress: string = "api/user/ResetPassword/";
    this.loginservice.resetPassword(apiAddress, JSON.stringify({ userName }))
      .subscribe(
        res => {

          if (res.responseCode == 'PasswordMismatch') {
            //this.showPasswordMismatch();
          }
          else if (res.responseCode == 'OK') {
            alert("Password reset successfully.")
            this.showResetPasswordSuccess();
            this.dialogRef.close(res);

          } else {
            //debugger;
            this.errorMessage = res.responseMessage;
           // this.showError(res.responseMessage);
            //this.dialogRef.close(res);

          }
        },


      );

    // 
  }
  // #endregion reset Password action

  onNoClick(): void {
    this.dialogRef.close();
  }

}
