/*
 *  Created by : Ganesh Gurav
*   Created on : 26-Nov-2018
 *  Description: This component is used for holiday add dialog.
 */

// #region Imports
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatAutocompleteModule, MAT_DIALOG_DATA } from '@angular/material';
import { RepositoryService } from '../../../Services/repository.service';
import { HolidayModel } from '../../../Models/holiday-model';
import { Validators, FormControl } from '@angular/forms';
import { ToasterService, Toast, ToasterConfig, BodyOutputType } from 'angular2-toaster';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material';
// #endregion

export const PICK_FORMATS = {
  parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};

export class PickDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    //debugger;
    if (displayFormat === 'input') {
      return new DatePipe('en-US').transform(date, 'dd-MMM-yyyy');
    } else {
      return date.toDateString();
    }
  }
}

@Component({
  selector: 'app-holiday-add-dialog',
  templateUrl: './holiday-add-dialog.component.html',
  styleUrls: ['./holiday-add-dialog.component.css'],
  providers: [
    { provide: DateAdapter, useClass: PickDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }
  ]
})


export class HolidayAddDialogComponent  implements OnInit {

  // #region Variables Declarations
  public loggedUserID: string;
  // #endregion Variables Declarations

  // #region Toaster Service registration and defualt position
  private toasterService: ToasterService;
  public config: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: false,
    positionClass: 'toast-top-center'
  });
  // #endregion

  // #region Constructors
  constructor(public dialogRef: MatDialogRef<HolidayAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: HolidayModel,
    private repository: RepositoryService,
    toasterService: ToasterService, MatAutocompleteModul: MatAutocompleteModule, private dateAdapter: DateAdapter<Date>) {
    this.toasterService = toasterService;
  }
  // #endregion Constructors and Validators

  // #region Toaster  Pop ups for actions
  showDuplicate() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'Delete', 'Duplicate Record!');
  }

  showSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Created!');
  }

  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }
  // #endregion Toaster  Pop ups for actions


  // #region  formControl Validators and Validators Message
  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);


  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      '';
  }
  // #endregion formControl Validators and Validators Message


  submit() {
    // emppty stuff
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


  public confirmAdd(): void {
    
    const pipe = new DatePipe('en-US');
    let postHolidaymodel: HolidayModel = {
      id: 0,
      holidayName: this.data.holidayName.trim(),
      holidayDate: pipe.transform(this.data.holidayDate, 'dd-MMM-yyyy'),
      //holidayDate: this.data.holidayDate,
      holidayYear: new Date(this.data.holidayDate).getFullYear(),
      active: 1,
      createdBy: this.loggedUserID,
      createdOn: null,
      modifiedBy: null,
      modifiedOn: null
    }
    let apiAddress: string = "api/holiday/post";

    this.repository.createResponse(apiAddress, postHolidaymodel)
      .subscribe(res => {

        if (res.responseCode == 'Conflict') {
          //console.log("conflict" + res.responseCode);
          this.showDuplicate();
        }
        else if (res.responseCode == 'OK') {
          this.showSuccess();
          this.dialogRef.close(res);

        } else if (res.responseCode == 'InternalServerError') {
          this.showError(res.responseMessage);
          this.dialogRef.close(res);

        }

      });
  }

  ngOnInit() {
    this.loggedUserID = localStorage.getItem('userId');
  }

}
