// #region Imports
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RepositoryService } from '../../../Services/repository.service';
import { CategoryModel } from '../../../Models/category-model';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ToasterService, Toast, ToasterConfig, BodyOutputType } from 'angular2-toaster';
import { DepartmentModel } from 'src/app/Models/department-model';
// #endregion

/*
 *  Created by : Sanjiv Shinde
*   Created on : 25-Oct-2018
 *  Description: This component is used for sub category add dialog.
 *
 *
 * */

@Component({
  selector: 'app-add-dialog',
  templateUrl: './add-dialog.component.html',
  styleUrls: ['./add-dialog.component.css']
})
export class AddDialogComponent implements OnInit {
  public loggedUserID: string;
  departmentModel: DepartmentModel[];
  categoryForm: FormGroup;

  // #region Toaster Service registration and defualt position

  private toasterService: ToasterService;

  public config: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: false,
    positionClass: 'toast-top-center'
  });

  // #endregion
  // #region Toaster  Pop ups for actions
  showDuplicate() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'Delete', 'Duplicate Record!');
  }

  showSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Created!');
  }

  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }
  // #endregion
  ngOnInit() {
    this.buildForm();
    this.loggedUserID = localStorage.getItem('userId');
    this.bindDepartmentDDL();
  }
  // #region Constructors and Validators


  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<AddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CategoryModel,
    private repository: RepositoryService,
    toasterService: ToasterService) {
    this.toasterService = toasterService;
  }

  //formControl = new FormControl('', [
  //  Validators.required
  //  // Validators.email,
  //]);


  buildForm() {
    this.categoryForm = this.fb.group({
      'categoryName': ['', [
        Validators.required
      ]
      ]
      ,
      'departmentId': ['', [
        //Validators.required,
        //Validators.pattern('^[1-9][0-9]*$'),

      ]
      ],
    });
  }

  //getErrorMessage() {
  //  return this.formControl.hasError('required') ? 'Required field' :
  //    this.formControl.hasError('email') ? 'Not a valid email' :
  //      '';
  //}
  // #endregion
  // #region Add Dialog  actions

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // #endregion
  // #region Add Dialog Save action

  public confirmAdd(): void {

    let postCategorymodel: CategoryModel = {
      id: 0,
      categoryName: this.data.categoryName,
      active: 1,
      createdBy: this.loggedUserID,
      createdOn: null,
      modifiedBy: null,
      modifiedOn: null,
      departmentId: this.categoryForm.controls['departmentId'].value,
    }
    let apiAddress: string = "api/category/post";

    this.repository.createResponse(apiAddress, postCategorymodel)
      .subscribe(res => {

        if (res.responseCode == 'Conflict') {
          //console.log("conflict" + res.responseCode);
          this.showDuplicate();
        }
        else if (res.responseCode == 'OK') {
          this.showSuccess();
          this.dialogRef.close(res);

        } else if (res.responseCode == 'InternalServerError') {
          this.showError(res.responseMessage);
          this.dialogRef.close(res);

        }

      });
  }

  // #endregion

  ///Bind Department list
  bindDepartmentDDL() {
    let apiAddress: string = "api/department/";

    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.departmentModel = res as DepartmentModel[];
        //console.log(this.dataSource);
      });
  }
}
