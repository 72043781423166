// #region Imports



import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogConfig, MatSortable } from '@angular/material';
import { CategoryModel } from '../../Models/category-model';
import { RepositoryService } from '../../Services/repository.service';
import { AddDialogComponent } from './add-dialog/add-dialog.component';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';

import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { Router } from '@angular/router';

// #endregion

/*
 *  Created by : Sanjiv Shinde
*   Created on : 25-Oct-2018
 *  Description: This component is used for category master.
 *
 *
 * */


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements AfterViewInit, OnInit {




  // #region Material Data Table declaration and initialisation


  public dataSource: MatTableDataSource<CategoryModel>
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['id', 'categoryName','departmentName', 'actions'];
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];

  //Table filter setting

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  ngOnInit() {

    this.loadData();
    // make default sort
    //let ortOrder: string = 'asc';
    //this.sort.sort(<MatSortable>({ id: 'categoryName', start: ortOrder }));
    //  this.dataSource.sort = this.sort;


  }

  ngAfterViewInit() {

    // reset the paginator after sorting
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

  }


  private refreshTable() {
    // Refreshing table using paginator
    // Thanks yeager-j for tips
    // https://github.com/marinantonio/angular-mat-table-crud/issues/12
    //debugger;
    this.loadData();
    this.paginator._changePageSize(this.paginator.pageSize);


  }

  //loads the data from web api
  public loadData() {

    let apiAddress: string = "api/category";

    this.repository.getData(apiAddress)

      .subscribe(res => {
        //debugger;
        this.categoryModel = res as CategoryModel[];
        this.dataSource = null;
        this.dataSource = new MatTableDataSource(this.categoryModel);
        this.dataSource.paginator = this.paginator;

        //reset defualt sort
       // let ortOrder: string = 'asc';
       // this.sort.sort(<MatSortable>({ id: 'categoryName', start: ortOrder }));


        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
          if (!data[sortHeaderId]) {
            return this.sort.direction === "asc" ? '3' : '1';
          }
          return '2' + data[sortHeaderId].toLocaleLowerCase();
        };

        this.dataSource.sort = this.sort;
      });
  }

  // #endregion

  // #region Variables Declarations

  categoryModel: CategoryModel[];

  // #endregion

  // #region Constructor to register repositoty and toasterService

  constructor(public dialog: MatDialog,
    private repository: RepositoryService, private router: Router) {


  }

  // #endregion

  // #region Add Dialog Methods

  addNew() {
    let categoryModel: CategoryModel;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = { categoryModel: categoryModel };
    const dialogRef = this.dialog.open(AddDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined) {

        this.refreshTable();
      } else if (result.responseCode == 'OK') {

        this.refreshTable();


      } else {

      }
    });
  }

  // #endregion

  // #region Edit Dialog Methods

  selectedRow(row: any): void {
    this.selectedRow = row; //console.log(row);
  }
  startEdit(i: number, id: number, categoryName: string, departmentId: number) {
    debugger;
    // index row is used just for debugging proposes and can be removed
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = { id: id, categoryName: categoryName, departmentId: departmentId };
    //console.log("before edit");
    //console.log(dialogConfig.data);
    const dialogRef = this.dialog.open(EditDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {


      if (result == undefined) {

        this.refreshTable();
      } else if (result.responseCode == 'OK') {

        this.refreshTable();


      } else {

      }
    });
  }


  // #endregion

  // #region Delete Dialog Methods


  deleteItem(i: number, id: number) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = { Id: id };
    const dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.responseCode == 'OK') {

        this.refreshTable();
        //   this.router.navigate(['/login']);
      } else {

      }
    });
  }

  // #endregion



}
