/*
 *  Created by : Ganesh Gurav
*   Created on : 26-Nov-2018
 *  Description: This component is used for edit sub category master.
 * */

// #region Imports
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatAutocompleteModule, MatSelect, MAT_DIALOG_DATA } from '@angular/material';
import { RepositoryService } from '../../../Services/repository.service';
import { Validators, FormControl } from '@angular/forms';
import { ToasterService, Toast, ToasterConfig, BodyOutputType } from 'angular2-toaster';
import { map, startWith } from 'rxjs/operators';
import { HolidayModel } from '../../../Models/holiday-model';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material';
// #endregion

export const PICK_FORMATS = {
  parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};

export class PickDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    //debugger;
    if (displayFormat === 'input') {
      return new DatePipe('en-US').transform(date, 'dd-MMM-yyyy');
    } else {
      return date.toDateString();
    }
  }
}

@Component({
  selector: 'app-holiday-edit-dialog',
  templateUrl: './holiday-edit-dialog.component.html',
  styleUrls: ['./holiday-edit-dialog.component.css'],
  providers: [
    { provide: DateAdapter, useClass: PickDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }
  ]
})

export class HolidayEditDialogComponent implements OnInit {

   // #region Variables Declarations
  public loggedUserID: string;
  public holDate: string;
  public holDate1: Date;
  // #endregion Variables Declarations

  // #region Toaster Service registration and defualt position
  private toasterService: ToasterService;
  public config: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: false,
    positionClass: 'toast-top-center'
  });
  // #endregion


  ngOnInit() {
    this.loggedUserID = localStorage.getItem('userId');
  }

  // #region Toaster  Pop ups for actions
  showUpdateSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Updated!');
  }
  showDuplicate() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'Duplicate', 'Duplicate Record!');
  }
  showSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Created!');
  }
  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }
  // #endregion Toaster  Pop ups for actions

  // #region Constructors
  constructor(public dialogRef: MatDialogRef<HolidayEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: HolidayModel,
    private repository: RepositoryService,
    toasterService: ToasterService, MatAutocompleteModul: MatAutocompleteModule, private dateAdapter: DateAdapter<Date>) {
    this.toasterService = toasterService;
    
    this.dateAdapter.setLocale('nl');
  }
  // #endregion Constructors and Validators

  // #region  formControl Validators and Validators Message
  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      '';
  }
  // #endregion formControl Validators and Validators Message

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  //#endregion loads region data from web api

  // #region Add Dialog Save action
  public updateHoliday(): void {
    //debugger;
    const pipe = new DatePipe('en-US');
   // var dfdf = new Date((this.data.holidayDate.getFullYear(), this.data.holidayDate.getMonth(), this.data.holidayDate.getDay())).toISOString();
    let postHolidaymodel: HolidayModel = {
      id: this.data.id,
      holidayName: this.data.holidayName.trim(),
      holidayDate: pipe.transform(this.data.holidayDate, 'dd-MMM-yyyy'),
      //holidayDate: this.data.holidayDate,

      holidayYear: new Date(this.data.holidayDate).getFullYear(),
      active: 1,
      createdBy: this.data.createdBy,
      createdOn: this.data.createdOn,
      modifiedBy: this.loggedUserID,
      modifiedOn: null,
    }
    let apiAddress: string = "api/holiday";
    this.repository.update(apiAddress, postHolidaymodel)
      .subscribe(res => {

        if (res.responseCode == 'Conflict') {
          //console.log("conflict" + res.responseCode);
          this.showDuplicate();
        }
        else if (res.responseCode == 'OK') {
          this.showUpdateSuccess();
          this.dialogRef.close(res);

        } else if (res.responseCode == 'InternalServerError') {
          this.showError(res.responseMessage);
          this.dialogRef.close(res);

        }

      });
  }
  // #endregion Add Dialog Save action


}
