import { Component, OnInit, ViewChild } from '@angular/core';
import { DepartmentModel } from 'src/app/Models/department-model';
import { MatTableDataSource, MatSort, MatDialog, MatPaginator, MatSortable, MatDialogConfig } from '@angular/material';
import { RepositoryService } from 'src/app/Services/repository.service';
import { Router } from '@angular/router';
import { DepartmentAddDialogComponent } from './department-add-dialog/department-add-dialog.component';
import { DepartmentEditDialogComponent } from './department-edit-dialog/department-edit-dialog.component';
import { DepartmentDeleteDialogComponent } from './department-delete-dialog/department-delete-dialog.component';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit {

  // #region Variables Declarations
  departmentModel: DepartmentModel[];
  department: DepartmentModel;

  // #endregion Variables Declarations

  // #region Material Data Table for source master, declaration and initialisation
  public dataSource: MatTableDataSource<DepartmentModel>
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['id', 'departmentName','departmentEmail', 'actions'];
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  // #endregion Material Data Table declaration and initialisation

  constructor(public dialog: MatDialog, private repository: RepositoryService, private router: Router) { }

  ngOnInit() {
    this.loadData();
  }

  //#region loads the source data from web api
  public loadData() {

    let apiAddress: string = "api/department";

    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.departmentModel = res as DepartmentModel[];
        this.dataSource = new MatTableDataSource(this.departmentModel);
        this.dataSource.paginator = this.paginator;
        //reset defaultsort
        //let ortOrder: string = 'asc';
        //this.sort.sort(<MatSortable>({ id: 'departmentName', start: ortOrder }));


        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
          if (!data[sortHeaderId]) {
            return this.sort.direction === "asc" ? '3' : '1';
          }
          return '2' + data[sortHeaderId].toLocaleLowerCase();
        };

        this.dataSource.sort = this.sort;
      });
  }
  //#endregion loads the source data from web api

  // #region Table filter setting
  //Table filter setting

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // #endregion Table filter setting


  // #region refresh table
  private refreshTable() {
    // Refreshing table using paginator
    // Thanks yeager-j for tips
    // https://github.com/marinantonio/angular-mat-table-crud/issues/12
    this.loadData();
    this.paginator._changePageSize(this.paginator.pageSize);
  }
  // #endregion refresh table


  // #region Add Dialog Methods
  addNew() {
    let departmentModel: DepartmentModel;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = { branchModel: departmentModel };
    const dialogRef = this.dialog.open(DepartmentAddDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined) {

        this.refreshTable();
      } else if (result.responseCode == 'OK') {

        this.refreshTable();


      } else {

      }
    });
  }
  // #endregion Add Dialog Methods]


  // #region Edit Dialog Methods
  selectedRow(row: any): void {
    this.selectedRow = row; //console.log(row);
  }
  startEdit(i: number, id: number, departmentName: string) {
    // index row is used just for debugging proposes and can be removed
    let apiAddress: string = "api/department/" + id;

    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.department = res as DepartmentModel;

        this.openDialog(id, this.department);
      });
  }
  public openDialog(id: number, department: DepartmentModel) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = department;
    const dialogRef = this.dialog.open(DepartmentEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined) {

        this.refreshTable();
      } else if (result.responseCode == 'OK') {

        this.refreshTable();


      } else {

      }
    });
  }
  // #endregion  Edit Dialog Methods


  // #region Delete Dialog Methods
  deleteItem(i: number, id: number) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = { Id: id };
    const dialogRef = this.dialog.open(DepartmentDeleteDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined) {

        this.refreshTable();
      } else if (result.responseCode == 'OK') {

        this.refreshTable();


      } else {

      }
    });
  }
  // #endregion Delete Dialog Methods

  // #region Get Branch for Edit Dialog Methods
  public LoadBranch(id: number) {
    let apiAddress: string = "api/department/" + id;
    //debugger;
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.department = res as DepartmentModel;
        //console.log(this.branch);
      });
  }
  // #endregion Get Branch for Edit Dialog Methods

}
