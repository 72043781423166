  // #region Imports
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RepositoryService } from '../../../Services/repository.service';
import { ToasterService, Toast, ToasterConfig, BodyOutputType } from 'angular2-toaster';
// #endregion

/*
 *  Created by : Sanjiv Shinde
*   Created on : 25-Oct-2018
 *  Description: This component is used for category Delete dialog.
 *
 *
 * */
@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {

  // #region Toaster Service registration and defualt position


  private toasterService: ToasterService;

  public config: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: false,

    positionClass: 'toast-top-center'
  });

  // #endregion

  // #region Toaster  Pop ups for actions


  showDelete() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Delete', 'Record Deleted!');
  }
  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }



  // #endregion

  ngOnInit() {
  }


  // #region Constructors and Validators

  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public repository: RepositoryService,
    toasterService: ToasterService) {
    this.toasterService = toasterService;
  }
   // #endregion

  // #region Delete Dialog  actions
  onNoClick(): void {
    this.dialogRef.close();
  }
   // #endregion

  // #region delete Dialog Save action
  confirmDelete(): void {

    let apiAddress: string = "api/category/" +  this.data.Id;
    //console.log("on delete");
   
    this.repository.delete(apiAddress)
      .subscribe(res => {
        console.log("delete result");
        console.log(res);
        if (res.responseCode == 'Conflict') {
      
        }
        else if (res.responseCode == 'OK') {
          this.showDelete();
          this.dialogRef.close(res);

        } else if (res.responseCode == 'InternalServerError') {
          this.showError(res.responseMessage);
          this.dialogRef.close(res);

        }


        

      }); 
  }
  // #endregion
}
