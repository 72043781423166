
//import { NgModule } from '@angular/core';
//import { BrowserModule } from '@angular/platform-browser';
//import { FormsModule } from '@angular/forms';
//import { HttpClientModule } from '@angular/common/http';
//import { RouterModule } from '@angular/router';
//import { NavMenuComponent } from './nav-menu/nav-menu.component';
//import { BranchComponent } from './branch/branch.component';
//import { HomeComponent } from './home/home.component';
////import { DataTablesModule } from 'angular-datatables';
//import { AppComponent } from './app.component';
//import { LazyLoadModule } from './lazy-load/lazy-load.module';
//import { CoreModule } from './core/core.module';

//@NgModule({
//  declarations: [
//    AppComponent,
//    HomeComponent,
//    NavMenuComponent,
   
//    BranchComponent,
//  ],
//  imports: [
//    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
//    HttpClientModule,
//    FormsModule,
//    LazyLoadModule,
//    CoreModule,
//    //RouterModule.forRoot([
//    // { path: '', component: HomeComponent, pathMatch: 'full' },
//    //  //{ path: 'counter', component: CounterComponent },
//    //  //{ path: 'fetch-data', component: FetchDataComponent },
//    //  { path: 'branch', component: BranchComponent },
//    //])

//  ],
//  providers: [],
//  bootstrap: [AppComponent]
//})
//export class AppModule { }


import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LazyLoadModule } from './lazy-load/lazy-load.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
 import { ToasterComponentModule } from './toaster.component';
import { ToasterModule } from 'angular2-toaster';
 
import { HttpModule } from '@angular/http';
import { AuthGuard } from './auth.guard';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { MatSidenavModule, MatAutocompleteModule } from '@angular/material';
import { MatTableExporterModule } from 'mat-table-exporter' 


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
   ],
  imports: [
    BrowserModule,
    LazyLoadModule,
    CoreModule, HttpModule,
    MatSidenavModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
  ToasterModule.forRoot(),
    ToasterComponentModule,
    MatTableExporterModule,
   
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
