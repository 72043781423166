/*
 *  Created by : Vishal Mahajan
*   Created on : 02-Nov-2018
 *  Description: This component is used for branch master.
 * */


// #region Imports
import { Component, OnInit, ViewChild } from '@angular/core';
import { BranchModel } from '../../Models/branch-model'
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatDialogConfig, MatSortable } from '@angular/material';
import { RepositoryService } from '../../Services/repository.service';
import { Router } from '@angular/router';
import { BranchDeleteDialogComponent } from './branch-delete-dialog/branch-delete-dialog.component';
import { BranchAddDialogComponent } from './branch-add-dialog/branch-add-dialog.component';
import { BranchEditDialogComponent } from './branch-edit-dialog/branch-edit-dialog.component';
// #endregion Imports

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css']
})
export class BranchComponent implements OnInit {

  // #region Variables Declarations
  branchModel: BranchModel[];
  branch: BranchModel;
  // #endregion Variables Declarations

  // #region Material Data Table for source master, declaration and initialisation
  public dataSource: MatTableDataSource<BranchModel>
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['id','branchCode', 'branchName','email','actions'];
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  // #endregion Material Data Table declaration and initialisation

  constructor(public dialog: MatDialog, private repository: RepositoryService, private router: Router) { }

  ngOnInit() {
    this.loadData();
  }
  
  //#region loads the source data from web api
  public loadData() {

    let apiAddress: string = "api/branch";
 
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.branchModel = res as BranchModel[];
        this.dataSource = new MatTableDataSource(this.branchModel);
        this.dataSource.paginator = this.paginator;
        //reset defaultsort
        //let ortOrder: string = 'asc';
        //this.sort.sort(<MatSortable>({ id: 'branchName', start: ortOrder }));


        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
          if (!data[sortHeaderId]) {
            return this.sort.direction === "asc" ? '3' : '1';
          }
          return '2' + data[sortHeaderId].toLocaleLowerCase();
        };

        this.dataSource.sort = this.sort;
      });
  }
  //#endregion loads the source data from web api

  // #region Table filter setting
  //Table filter setting

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // #endregion Table filter setting


  // #region refresh table
  private refreshTable() {
    // Refreshing table using paginator
    // Thanks yeager-j for tips
    // https://github.com/marinantonio/angular-mat-table-crud/issues/12
    this.loadData();
    this.paginator._changePageSize(this.paginator.pageSize);
  }
  // #endregion refresh table


  // #region Add Dialog Methods
  addNew() {
    let branchModel: BranchModel;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = { branchModel: branchModel };
    const dialogRef = this.dialog.open(BranchAddDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined) {

        this.refreshTable();
      } else if (result.responseCode == 'OK') {

        this.refreshTable();


      } else {

      }
    });
  }
  // #endregion Add Dialog Methods]


  // #region Edit Dialog Methods
  selectedRow(row: any): void {
    this.selectedRow = row; //console.log(row);
  }
  startEdit(i: number, id: number, branchName: string) {
    // index row is used just for debugging proposes and can be removed
    let apiAddress: string = "api/branch/" + id;
   
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.branch = res as BranchModel;
        
        this.openDialog(id, this.branch);
      });
  }
  public openDialog(id:number, branch: BranchModel) {

    const dialogConfig = new MatDialogConfig();
   
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = branch;
    const dialogRef = this.dialog.open(BranchEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined) {

        this.refreshTable();
      } else if (result.responseCode == 'OK') {

        this.refreshTable();


      } else {

      }
    });
  }
  // #endregion  Edit Dialog Methods


  // #region Delete Dialog Methods
  deleteItem(i: number, id: number) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = { Id: id };
    const dialogRef = this.dialog.open(BranchDeleteDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined) {

        this.refreshTable();
      } else if (result.responseCode == 'OK') {

        this.refreshTable();


      } else {

      }
    });
  }
  // #endregion Delete Dialog Methods

  // #region Get Branch for Edit Dialog Methods
  public LoadBranch(id:number) {
    let apiAddress: string = "api/branch/"+id;
    //debugger;
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.branch = res as BranchModel;
        //console.log(this.branch);
      });
  }
  // #endregion Get Branch for Edit Dialog Methods
}
