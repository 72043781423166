/*
 *  Created by : Vishal Mahajan
*   Created on : 02-Nov-2018
 *  Description: This component is used for add source master.
 * */

// #region Imports
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RepositoryService } from '../../../Services/repository.service';
import { Validators, FormControl } from '@angular/forms';
import { ToasterService, Toast, ToasterConfig, BodyOutputType } from 'angular2-toaster';
import { SourceModel } from '../../../Models/source-model';
// #endregion

@Component({
  selector: 'app-source-add-dialog',
  templateUrl: './source-add-dialog.component.html',
  styleUrls: ['./source-add-dialog.component.css']
})
export class SourceAddDialogComponent implements OnInit {
  // #region Variables Declarations
  public loggedUserID: string;
  // #endregion Variables Declarations


  // #region Toaster Service registration and defualt position
  private toasterService: ToasterService;
  public config: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: false,
    positionClass: 'toast-top-center'
  });
  // #endregion

  // #region Constructors
  constructor(public dialogRef: MatDialogRef<SourceAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SourceModel,
    private repository: RepositoryService,
    toasterService: ToasterService) {
    this.toasterService = toasterService;
  }
  // #endregion Constructors and Validators

  // #region Toaster  Pop ups for actions
  showDuplicate() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'Delete', 'Duplicate Record!');
  }

  showSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Created!');
  }

  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }
  // #endregion Toaster  Pop ups for actions

  // #region  formControl Validators and Validators Message
  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);


  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }
 // #endregion formControl Validators and Validators Message


  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  // #region Add Dialog Save action
  public confirmAdd(): void {

    let postCategorymodel: SourceModel = {
      id: 0,
      sourceName: this.data.sourceName.trim(),
      active: 1,
      createdBy: this.loggedUserID,
      createdOn: null,
      modifiedBy: null,
      modifiedOn: null
    }
    let apiAddress: string = "api/source/post";

    this.repository.createResponse(apiAddress, postCategorymodel)
      .subscribe(res => {

        if (res.responseCode == 'Conflict') {
          //console.log("conflict" + res.responseCode);
          this.showDuplicate();
        }
        else if (res.responseCode == 'OK') {
          this.showSuccess();
          this.dialogRef.close(res);

        } else if (res.responseCode == 'InternalServerError') {
          this.showError(res.responseMessage);
          this.dialogRef.close(res);
        }

      });
  }
  // #endregion Add Dialog Save action

  ngOnInit() {
    this.loggedUserID = localStorage.getItem('userId');
  }

}
