import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, ErrorStateMatcher } from '@angular/material';
import { LoginService } from '../../../Services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, NgForm, FormGroupDirective } from '@angular/forms';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { UserModel } from '../../../Models/user-model';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}


@Component({
  selector: 'app-change-paswword-dialog',
  templateUrl: './change-paswword-dialog.component.html',
  styleUrls: ['./change-paswword-dialog.component.css']
})
export class ChangePaswwordDialogComponent implements OnInit {


  // #region Variables Declarations
  public loggedUserID: string;
  changePasswordForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  // #endregion Variables Declarations

  // #region Toaster Service registration and defualt position
  private toasterService: ToasterService;
  public config: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: false,
    positionClass: 'toast-top-center'
  });
  // #endregion



  ngOnInit() {
    //debugger;
    this.loggedUserID = localStorage.getItem('userId');
    this.buildForm();
  }

  // #region Toaster  Pop ups for actions
  showChangePasswordSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Updated!');
  }
  showPasswordMismatch() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'Error', 'Password Mismatch!');
  }
  showSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Created!');
  }
  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }
  // #endregion Toaster  Pop ups for actions

  // #region Constructors
  constructor(public dialogRef: MatDialogRef<ChangePaswwordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserModel,
    // private repository: RepositoryService,
    toasterService: ToasterService, private loginservice: LoginService, private fb: FormBuilder, private router: Router) {
    this.toasterService = toasterService
  }
  // #endregion Constructors and Validators

  // #region  formControl Validators and Validators Message
  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  currentPassword = new FormControl();
  newPassword = new FormControl();
  confirmPassword = new FormControl();

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      '';
  }
  // #endregion formControl Validators and Validators Message

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  formErrors = {

    'currentPassword': '',
    'newPassword': '',
    'confirmPassword': '',

  };
  validationMessages = {

    'currentPassword': {
      'required': 'Please enter password',
      'minlength': 'Please enter more than 6 characters',
      'maxlength': 'Please enter less than 25 characters',
    },
    'newPassword': {
      'required': 'Please enter password',
      'minlength': 'Please enter more than 6 characters',
      'maxlength': 'Please enter less than 25 characters',
    },
    'confirmPassword': {
      'required': 'Please enter password',
      'minlength': 'Please enter more than 6 characters',
      'maxlength': 'Please enter less than 25 characters',
      'notSame': 'Password Not Match'
    },
  };

  buildForm() {

    this.changePasswordForm = this.fb.group({
      'userId': ['', [

      ]
      ],

      'currentPassword': ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(25)
      ]
      ],

      'newPassword': ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(25)
      ]
      ],

      'confirmPassword': ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(25),


      ]
      ],
    },
      { validator: this.checkPasswords }
    );

    // #endregion


    this.changePasswordForm.valueChanges.subscribe(
      data => this.onValueChanged(data)

    );
    this.onValueChanged();
  }

  onValueChanged(data?: any) {

    if (!this.changePasswordForm) {
      return;
    }
    const form = this.changePasswordForm;
    //debugger;
    for (const field in this.formErrors) {
      //debugger;
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        //debugger;
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        } else {
          const messages = this.validationMessages[field];
         
          if (field == 'confirmPassword' && form.errors != null && form.errors["notSame"] == true) {
            this.formErrors[field] = messages['notSame'];
          }
        }
      }
    }
  }
  // #region Change Password action
  public changePassword(): void {
    //debugger;
    let userId = this.loggedUserID;
    let currentPassword = this.changePasswordForm.value.currentPassword;
    let newPassword = this.changePasswordForm.value.newPassword;

    let apiAddress: string = "api/user/ChangePassword/";
    this.loginservice.changePassword(apiAddress, JSON.stringify({ userId, currentPassword, newPassword }))
      .subscribe(
        res => {

          if (res.responseCode == 'PasswordMismatch') {
            this.showPasswordMismatch();
          }
          else if (res.responseCode == 'OK') {
            alert("Password changed successfully.")
            this.showChangePasswordSuccess();
            this.logout();
            this.dialogRef.close(res);

          } else if (res.responseCode == 'InternalServerError') {
            this.showError(res.responseMessage);
            this.dialogRef.close(res);

          }
        },
        //error => { this.errors = "Invalid username or password" }//this.envUrl + 'api/auth/login',

      );

    // 
  }
  // #endregionChange Password action

  logout() {

    this.loginservice.logout();

    this.router.navigate(['/login']);
  }

  //check new and confirm password
  checkPasswords(group: FormGroup) {

    let pass = group.controls['newPassword'].value;
    let confirmPass = group.controls['confirmPassword'].value;

    return pass === confirmPass ? null : { notSame: true }
  }
}
