import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatDialogModule, MatProgressSpinnerModule } from '@angular/material';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { HttpErrorInterceptor } from '../error-handler.interceptor';

import { MatTableModule } from '@angular/material/table';
import {
  MatPaginatorModule,
  MatSortModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatChipsModule, MatSelectModule,
  MatButtonToggleModule,
} from '@angular/material';



import * as hljs from 'highlight.js';
import { HighlightJsModule, HIGHLIGHT_JS } from 'angular-highlight-js';
import * as hljsTypescript from 'highlight.js/lib/languages/typescript';
import { CategoryComponent } from './category/category.component';
import { AddDialogComponent } from './category/add-dialog/add-dialog.component';
import { administratorRouterModule } from './administrator.router';
import { EditDialogComponent } from './category/edit-dialog/edit-dialog.component';
import { ToasterModule } from 'angular2-toaster';
import { DeleteDialogComponent } from './category/delete-dialog/delete-dialog.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { SubAddDialogComponent } from './sub-category/sub-add-dialog/sub-add-dialog.component';
import { SubEditDialogComponent } from './sub-category/sub-edit-dialog/sub-edit-dialog.component';
import { SubDeleteDialogComponent } from './sub-category/sub-delete-dialog/sub-delete-dialog.component';
import { BranchComponent } from './branch/branch.component';
import { HolidayComponent } from './holiday/holiday.component';
import { SourceComponent } from './source/source.component';
import { AdministratorComponent } from './administrator.component';
import { SourceAddDialogComponent } from './source/source-add-dialog/source-add-dialog.component';
import { SourceEditDialogComponent } from './source/source-edit-dialog/source-edit-dialog.component';
import { SourceDeleteDialogComponent } from './source/source-delete-dialog/source-delete-dialog.component';
import { BranchAddDialogComponent } from './branch/branch-add-dialog/branch-add-dialog.component';
import { BranchDeleteDialogComponent } from './branch/branch-delete-dialog/branch-delete-dialog.component';
import { BranchEditDialogComponent } from './branch/branch-edit-dialog/branch-edit-dialog.component';
import { RegionComponent } from './region/region.component';
import { UserComponent } from './user/user.component';
import { UserAddDialogComponent } from './user/user-add-dialog/user-add-dialog.component';
import { UserEditDialogComponent } from './user/user-edit-dialog/user-edit-dialog.component';
import { UserDeleteDialogComponent } from './user/user-delete-dialog/user-delete-dialog.component';
import { HolidayAddDialogComponent } from './holiday/holiday-add-dialog/holiday-add-dialog.component';
import { HolidayEditDialogComponent } from './holiday/holiday-edit-dialog/holiday-edit-dialog.component';
import { HolidayDeleteDialogComponent } from './holiday/holiday-delete-dialog/holiday-delete-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChangePaswwordDialogComponent } from '../pages/login/change-paswword-dialog/change-paswword-dialog.component';
import { ForgotPasswordDialogComponent } from '../pages/login/forgot-password-dialog/forgot-password-dialog.component';
import { DepartmentComponent } from './department/department.component';
import { DepartmentAddDialogComponent } from './department/department-add-dialog/department-add-dialog.component';
import { DepartmentEditDialogComponent } from './department/department-edit-dialog/department-edit-dialog.component';
import { DepartmentDeleteDialogComponent } from './department/department-delete-dialog/department-delete-dialog.component';



export function highlightJsFactory(): any {
  hljs.registerLanguage('typescript', hljsTypescript);
  return hljs;
}


@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatToolbarModule,
    MatListModule,
    MatStepperModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    administratorRouterModule,
    MatSelectModule,
    
    HighlightJsModule.forRoot({
      provide: HIGHLIGHT_JS,
      useFactory: highlightJsFactory
    }),
    MatDialogModule,
    ToasterModule.forRoot(),
    HttpClientModule
  ],
  entryComponents: [
    AddDialogComponent,
    EditDialogComponent,
    DeleteDialogComponent,
    SourceAddDialogComponent,
    SourceEditDialogComponent,
    SourceDeleteDialogComponent,
    BranchAddDialogComponent,
    BranchDeleteDialogComponent,
    BranchEditDialogComponent,
    RegionComponent,
    UserAddDialogComponent,
    UserEditDialogComponent,
    UserDeleteDialogComponent,
    SubCategoryComponent,
    SubAddDialogComponent,
    SubEditDialogComponent,
    SubDeleteDialogComponent,
    HolidayAddDialogComponent,
    HolidayEditDialogComponent,
    HolidayDeleteDialogComponent,
    ChangePaswwordDialogComponent,
    ForgotPasswordDialogComponent,
    DepartmentAddDialogComponent,
    DepartmentDeleteDialogComponent,
    DepartmentEditDialogComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [

    CategoryComponent,
    AddDialogComponent,
    EditDialogComponent,
    DeleteDialogComponent,
    SubCategoryComponent,
    SubAddDialogComponent,
    SubEditDialogComponent,
    SubDeleteDialogComponent,
    BranchComponent,
    HolidayComponent,
    SourceComponent,
    AdministratorComponent,
    SourceAddDialogComponent,
    SourceEditDialogComponent,
    SourceDeleteDialogComponent,
    BranchAddDialogComponent,
    BranchDeleteDialogComponent,
    BranchEditDialogComponent,
    RegionComponent,
    UserComponent,
    UserAddDialogComponent,
    UserEditDialogComponent,
    UserDeleteDialogComponent,
    HolidayAddDialogComponent,
    HolidayEditDialogComponent,
    HolidayDeleteDialogComponent,
    ChangePaswwordDialogComponent,
    ForgotPasswordDialogComponent,
    DepartmentComponent,
    DepartmentAddDialogComponent,
    DepartmentEditDialogComponent,
    DepartmentDeleteDialogComponent
  ],
  providers: [
    //{
    ////provide: HTTP_INTERCEPTORS,
    ////useClass: HttpErrorInterceptor,
    ////multi: true,
    //}
  ],
  exports: [
  ]

})
export class AdministratorModule { }
