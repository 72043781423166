/*
 *  Created by : Ganesh Gurav
*   Created on : 22-Nov-2018
 *  Description: This component is used for edit sub category master.
 * */

// #region Imports
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatAutocompleteModule, MatSelect, MAT_DIALOG_DATA } from '@angular/material';
import { RepositoryService } from '../../../Services/repository.service';
import { Validators, FormControl } from '@angular/forms';
import { ToasterService, Toast, ToasterConfig, BodyOutputType } from 'angular2-toaster';
import { map, startWith } from 'rxjs/operators';
import { SubCategoryModel } from '../../../Models/subCategory-model';
import { CategoryModel } from '../../../Models/category-model';
import { Observable } from 'rxjs';
// #endregion


@Component({
  selector: 'app-sub-edit-dialog',
  templateUrl: './sub-edit-dialog.component.html',
  styleUrls: ['./sub-edit-dialog.component.css']
})
export class SubEditDialogComponent implements OnInit {

  // #region Variables Declarations
  public loggedUserID: string;
  categories: CategoryModel[];
  // #endregion Variables Declarations

  // #region Toaster Service registration and defualt position
  private toasterService: ToasterService;
  public config: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: false,
    positionClass: 'toast-top-center'
  });
  // #endregion

  filteredOptions: Observable<CategoryModel[]>;

  ngOnInit() {
    this.loggedUserID = localStorage.getItem('userId');
  }

  // #region Toaster  Pop ups for actions
  showUpdateSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Updated!');
  }
  showDuplicate() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'Duplicate', 'Duplicate Record!');
  }
  showSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Created!');
  }
  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }
  // #endregion Toaster  Pop ups for actions

  // #region Constructors
  constructor(public dialogRef: MatDialogRef<SubEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SubCategoryModel,
    private repository: RepositoryService,
    toasterService: ToasterService, MatAutocompleteModul: MatAutocompleteModule) {
    this.toasterService = toasterService,
    this.LoadCategory();
  }
  // #endregion Constructors and Validators

  // #region  formControl Validators and Validators Message
  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
        '';
  }
  // #endregion formControl Validators and Validators Message

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }



  public stopEdit(): void {

    let postSubCategorymodel: SubCategoryModel = {
      id: this.data.id,
      subCategoryName: this.data.subCategoryName.trim(),
      categoryId: this.data.categoryId,
      active: 1,
      createdBy: this.data.createdBy,
      createdOn: this.data.createdOn,
      modifiedBy: this.loggedUserID,
      modifiedOn: null
    }
    let apiAddress: string = "api/subCategory";

    this.repository.update(apiAddress, postSubCategorymodel)
      .subscribe(res => {

        if (res.responseCode == 'Conflict') {
          //console.log("conflict" + res.responseCode);
          this.showDuplicate();
        }
        else if (res.responseCode == 'OK') {
          this.showUpdateSuccess();
          this.dialogRef.close(res);

        } else if (res.responseCode == 'InternalServerError') {
          this.showError(res.responseMessage);
          this.dialogRef.close(res);

        }

      });
  }

  //#region loads category data from web api
  public LoadCategory() {

    let apiAddress: string = "api/category";
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.categories = res as CategoryModel[];
        console.log(this.categories);
        this.filteredOptions = this.formControl.valueChanges
          .pipe(
            startWith<string | CategoryModel>(''),
            map(value => typeof value === 'string' ? value : value.categoryName),
            map(name => name ? this._filter(name) : this.categories.slice())
          );
      });
  }

  private _filter(name: string): CategoryModel[] {
    const filterValue = name.toLowerCase();

    return this.categories.filter(option => option.categoryName.toLowerCase().indexOf(filterValue) === 0);
  }

  //#endregion loads region data from web api

   // #region Add Dialog Save action
  public updateSubCategory(): void {

    let postSubCategorymodel: SubCategoryModel = {
      id: this.data.id,
      subCategoryName: this.data.subCategoryName.trim(),
      categoryId: this.data.categoryId,
      active: 1,
      createdBy: this.data.createdBy,
      createdOn: this.data.createdOn,
      modifiedBy: this.loggedUserID,
      modifiedOn: null
    }
    let apiAddress: string = "api/subCategory";

    this.repository.update(apiAddress, postSubCategorymodel)
      .subscribe(res => {

        if (res.responseCode == 'Conflict') {
          //console.log("conflict" + res.responseCode);
          this.showDuplicate();
        }
        else if (res.responseCode == 'OK') {
          this.showUpdateSuccess();
          this.dialogRef.close(res);

        } else if (res.responseCode == 'InternalServerError') {
          this.showError(res.responseMessage);
          this.dialogRef.close(res);

        }

      });
  }
  // #endregion Add Dialog Save action
}
