import { Component, OnInit, Input } from '@angular/core';
import { menus, menusBM, menusCCE } from './menu-element';
import { LoggedUserRoleModel } from '../../Models/loggedUserRole-model';
import { RepositoryService } from '../../Services/repository.service';

@Component({
  selector: 'cdk-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

  loggedUserRoleModel: LoggedUserRoleModel[];
    @Input() iconOnly:boolean = false;
    public menus = menus;
  transform(roles: any, searchText: any): any {
    if (searchText == null) return roles;

    return this.loggedUserRoleModel.filter(function (roleItem) {
      return roleItem.roleName.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    })
  }
  constructor(private repository: RepositoryService,) {

  }

  ngOnInit() {
    //console.log(menus[0]);

    //get logged user role name
    let apiAddress1: string = "api/user/GetLoggedUserRoles/" + localStorage["userId"];
    this.repository.getData(apiAddress1)
      .subscribe(
        res => {

          this.loggedUserRoleModel = JSON.parse(JSON.stringify(res));


          localStorage.setItem('loggedUserRoles', JSON.stringify(this.loggedUserRoleModel));





          //check user has more than 1 roles then take highest role
          // Role JGM - > Level1
          // Role DJGM -> Level2
          // Role BM->Level3
          // Role CCE -> Level4
          // Role admin -> Level5

          if (this.loggedUserRoleModel != null) {
            if (this.loggedUserRoleModel.length >= 1) {
                if (this.transform(this.loggedUserRoleModel, 'CCE').length > 0) {
                this.menus = menusCCE;
              }else if (this.transform(this.loggedUserRoleModel, 'admin').length > 0) {

              } else if (this.transform(this.loggedUserRoleModel, 'jgm').length > 0) {
                this.menus = menusBM;
              } else if (this.transform(this.loggedUserRoleModel, 'DGM').length > 0) {
                this.menus = menusBM;

              } else if (this.transform(this.loggedUserRoleModel, 'Branch User').length > 0) {

                this.menus = menusBM;
                } else if (this.transform(this.loggedUserRoleModel, 'HOD').length > 0) {

                  this.menus = menusBM;
                }            }
          }

      });

    
    }

}
