/*
 *  Created by : Sanjiv Shinde
*   Created on : 21-Nov-2018
 *  Description: This component is used for add user master.
 * */

// #region Imports


import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatAutocompleteModule, MatSelect, MAT_DIALOG_DATA } from '@angular/material';
import { RepositoryService } from '../../../Services/repository.service';
import { Validators, FormControl, ReactiveFormsModule, FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { ToasterService, Toast, ToasterConfig, BodyOutputType } from 'angular2-toaster';
 
import { RegionModel } from '../../../Models/region-model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserModel } from '../../../Models/user-model';
import { RoleModel } from '../../../Models/role-model';
import { BranchModel } from 'src/app/Models/branch-model';
 
// #endregion

@Component({
  selector: 'app-user-add-dialog',
  templateUrl: './user-add-dialog.component.html',
  styleUrls: ['./user-add-dialog.component.css']
})
export class UserAddDialogComponent implements OnInit {

  // #region Variables Declarations
  public loggedUserID: string;
  addUserForm: FormGroup;
  roleModel: RoleModel[];

  branchModel: BranchModel[];
  selectedBranches: BranchModel[];
  // #endregion Variables Declarations

  // #region Toaster Service registration and defualt position
  private toasterService: ToasterService;


  public toasterConfig: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: true,
    timeout: 0,

    positionClass: 'toast-top-right'
  });
  // #endregion
  // #region Constructors
  constructor(public dialogRef: MatDialogRef<UserAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserModel,
    private repository: RepositoryService,
    toasterService: ToasterService, MatAutocompleteModul: MatAutocompleteModule, private fb: FormBuilder) {
    this.toasterService = toasterService;
  }
  // #endregion Constructors and Validators

  // #region Toaster  Pop ups for actions
  showDuplicate(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'Duplicate', responseMessage);
  }

  showSuccess(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', responseMessage);
  }

  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }
  // #endregion Toaster  Pop ups for actions

  

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  

  // #region Add Dialog Save action
  public saveUser(form: NgForm): void {
  
   
    // stop here if form is invalid
    if (this.addUserForm.invalid) {
      return;
    }
    const result1 = JSON.parse(JSON.stringify(form));
 
    
    let apiAddress: string = "api/user/post";

    this.repository.createResponse(apiAddress, result1)
      .subscribe(res => {

        if (res.responseCode == 'DuplicateUserName') {
          //console.log("conflict" + res.responseCode);
          this.showDuplicate(res.responseMessage);
        }
        else if (res.responseCode == 'OK') {
          this.showSuccess(res.responseMessage);
          this.dialogRef.close(res);

        } else if (res.responseCode == 'InternalServerError') {
          this.showError(res.responseMessage);
          this.dialogRef.close(res);
        }

      });
  }
  // #endregion Add Dialog Save action

  ngOnInit() {
    this.LoadRole();
    this.buildForm();
    this.loggedUserID = localStorage.getItem('userId');
    this.bindBranchDDL();
  }

  //#region loads Roles from web api
  public LoadRole() {

    let apiAddress: string = "api/user/GetAllRoles";
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.roleModel = res as RoleModel[];
        

      });



  }

  //#endregion loads region data from web api

  //#region Bind Dropdown List


  bindBranchDDL() {
    let apiAddress: string = "api/branch/";

    this.repository.getData(apiAddress)

      .subscribe(res => {
        this.branchModel = res as BranchModel[];
        this.selectedBranches = this.branchModel;
        //console.log(this.dataSource);
      });
  }

 // #region  formControl Validators and Validators Message



  formErrors = {
    'employeeCode': '',
    'firstName': '',
    'lastName': '',
    'email': '',
    'phoneNumber': '',
    'userName': '',
    'password': '',
    'role':''
  };
  validationMessages = {
    'employeeCode': {
      'required': 'Please enter your employee code'
    },
    'firstName': {
      'required': 'Please enter your first name' 
    },
    'lastName': {
     'required': 'Please enter your last name',
     
    },
    'email': {
      'required': 'Please enter valid email',
      'pattern': 'Please enter valid email'
    },
    'phoneNumber': {
      'required': 'Please enter mobile no.',
      
    },
    'role': {
      'required': 'Please select role',

    },
    'userName': {
      'required': 'Please enter user name',

    },
    'password': {
      'required': 'Please enter password',
      'minlength': 'Please enter more than 6 characters',
      'maxlength': 'Please enter less than 25 characters',
    },
  };

  // #endregion

  // #region Create initial form

  ngAfterViewInit() {


  }
  buildForm() {

  
    
    this.addUserForm = this.fb.group({
      'id': ['', [
      
      ]
      ],'employeeCode': ['', [
           Validators.required
        ]
        ],
        'firstName': ['', [
          Validators.required
        ]
        ]
        ,
      'lastName': ['', [
       // Validators.required
        ]
      ],
      'email': ['', [
        // Validators.required,
        Validators.pattern('^[a-z0-9._]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]
      ],
        'phoneNumber': ['', [
         // Validators.required
        ]
        ],
        'userName': ['', [
          Validators.required
        ]
      ],
        
      'role': ['', [
        Validators.required
      ]
      ],
        'password': ['', [
          Validators.required,
          Validators.minLength(6),
        Validators.maxLength(25)
        

        ]
      ],

      'branchId': ['', [
        //Validators.required
      ]
      ],
      



      });
 
 

     
    
     


     


    this.addUserForm.valueChanges.subscribe(
      data => this.onValueChanged(data)

    );
    this.onValueChanged();
  }

    // #endregion
  onValueChanged(data?: any) {

    if (!this.addUserForm) {
      return;
    }
    const form = this.addUserForm;

    for (const field in this.formErrors) {
     
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

    // #endregion
}
