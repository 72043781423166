// #region Imports
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RepositoryService } from '../../../Services/repository.service';
import { CategoryModel } from '../../../Models/category-model';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';

import { ToasterService, Toast, ToasterConfig, BodyOutputType } from 'angular2-toaster';
import { DepartmentModel } from 'src/app/Models/department-model';

// #endregion

/*
 *  Created by : Sanjiv Shinde
*   Created on : 25-Oct-2018
 *  Description: This component is used for category edit dialog.
 *
 *
 * */

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.css']
})
export class EditDialogComponent implements OnInit {
  public loggedUserID: string;
  departmentModel: DepartmentModel[];
  categoryForm: FormGroup;

  ngOnInit() {
    this.loggedUserID = localStorage.getItem('userId');
    this.bindDepartmentDDL();
    this.buildForm();
  }

  // #region Toaster Service registration and defualt position


  private toasterService: ToasterService;

  public config: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: false,
    positionClass: 'toast-top-center'
  });

  // #endregion


  // #region Toaster  Pop ups for actions

  showUpdateSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Updated!');
  }
  showDuplicate() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'Duplicate', 'Duplicate Record!');
  }

  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }
  // #endregion


  // #region Constructors and Validators


  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CategoryModel,
    private repository: RepositoryService,
    toasterService: ToasterService) {
    this.toasterService = toasterService;
  }

  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  buildForm() {
    this.categoryForm = this.fb.group({
      'categoryName': ['', [
        Validators.required
      ]
      ]
      ,
      'departmentId': ['', [
        //Validators.required,
        //Validators.pattern('^[1-9][0-9]*$'),

      ]
      ],
    });
    if (this.data) {
      this.categoryForm.controls['categoryName'].setValue(this.data.categoryName);
      this.categoryForm.controls['departmentId'].setValue(this.data.departmentId == null ? 0 : this.data.departmentId.toString());
    }

  }

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  // #endregion

  // #region Edit Dialog  actions
  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  // #endregion

  // #region Edit Dialog Save action
  stopEdit(): void {

    let postCategorymodel: CategoryModel = {
      id: this.data.id,
      categoryName: this.categoryForm.controls['categoryName'].value,
      active: 1,
      createdBy: null,
      createdOn: null,
      modifiedBy: this.loggedUserID,
      modifiedOn: null,
      departmentId: this.categoryForm.controls['departmentId'].value,
    }
    let apiAddress: string = "api/category/";

    this.repository.update(apiAddress, postCategorymodel)
      .subscribe(res => {
        //console.log("responseCode");
        //console.log(res);
        if (res.responseCode == 'Conflict') {
          //console.log("conflict" + res.responseCode);
          this.showDuplicate();
        }
        else if (res.responseCode == 'OK') {
          this.showUpdateSuccess();
          this.dialogRef.close(res);

        } else if (res.responseCode == 'InternalServerError') {
          this.showError(res.responseMessage);
          this.dialogRef.close(res);

        }

      });
    // this.repository.update(this.data);
  }

  ///Bind Department list
  bindDepartmentDDL() {
    let apiAddress: string = "api/department/";

    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.departmentModel = res as DepartmentModel[];
        //console.log(this.dataSource);
      });
  }

  // #endregion

}
