// #region Imports
import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../../Services/repository.service';
// #endregion Imports

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.css']
})
export class RegionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.loadData();
  }


  //#region loads the source data from web api
  public loadData() {

    let apiAddress: string = "api/source";

  }
   //#endregion loads the source data from web api
}
