import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseModel } from '../Models/response-model';
 
 
@Injectable()
export class RepositoryService {
    envUrl: any;
  token: any;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.envUrl = baseUrl;
    this.token = localStorage.getItem('auth_token');
  }
 
  public getData(route: string) {
    return this.http.get(this.createCompleteRoute(route, this.envUrl), this.generateHeaders());
  }
 
  public create(route: string, body) {
 
  
    return this.http.post(this.createCompleteRoute(route, this.envUrl), body, this.generateHeaders());
  }
  public createResponse(route: string, body) {


    return this.http.post<ResponseModel>(this.createCompleteRoute(route, this.envUrl), body, this.generateHeaders());
  }
  public update(route: string, body) {
    //console.log("in update service");
    return this.http.put<ResponseModel>(this.createCompleteRoute(route, this.envUrl), body, this.generateHeaders());
  }
 
  public delete(route: string){
    return this.http.delete<ResponseModel>(this.createCompleteRoute(route, this.envUrl), this.generateHeaders());
  }
 
  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}${route}`;
  }
 private generateHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        'Access-Control-Allow-Origin': '*',
      }),
    };
  }
 // private generateHeaders() {
  //  return {
 //     headers: new HttpHeaders({'Content-Type': 'application/json'})
//    }
//  }
}
